import { render, staticRenderFns } from "./Sidebar.vue?vue&type=template&id=07c23ab4&scoped=true"
import script from "./Sidebar.vue?vue&type=script&lang=js"
export * from "./Sidebar.vue?vue&type=script&lang=js"
import style0 from "./Sidebar.scss?vue&type=style&index=0&id=07c23ab4&prod&lang=scss&scoped=true&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "07c23ab4",
  null
  
)

export default component.exports
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "price-table"
  }, [_c('div', {
    staticClass: "table-title"
  }, [_vm._v("On chain transactions")]), _vm.data == null ? _c('vue-loaders-ball-beat', {
    staticClass: "mx-auto",
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "0.6"
    }
  }) : _c('div', [_c('b-table', {
    staticClass: "pull-model",
    attrs: {
      "id": "prices-table",
      "sort-by": "time",
      "sort-desc": true,
      "stacked": "md",
      "hover": "",
      "items": _vm.visibleTransactions,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "table-busy",
      fn: function fn() {
        return [_c('vue-loaders-ball-beat', {
          attrs: {
            "color": "var(--redstone-red-color)",
            "scale": "0.8"
          }
        })];
      },
      proxy: true
    }, {
      key: "cell(value)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "price"
        }, [!_vm.specialDenomination ? _c('strong', {
          domProps: {
            "innerHTML": _vm._s(_vm.parseToCurrency(item.value, _vm.denomination, _vm.token, _vm.bigDec))
          }
        }) : _c('strong', [_vm._v(_vm._s(_vm.denomination) + " "), _c('span', {
          domProps: {
            "innerHTML": _vm._s(_vm.formatPriceWithoutCurrency(item.value, _vm.specialDenomination, _vm.bigDec))
          }
        })])])];
      }
    }, {
      key: "cell(time)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "time"
        }, [_vm._v(" " + _vm._s(item.time) + " ")])];
      }
    }, {
      key: "cell(transactionHash)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('a', {
          staticClass: "txHash",
          attrs: {
            "target": "_blank",
            "href": item.url
          }
        }, [_vm._v(" " + _vm._s("".concat(item.txHash.slice(0, 10), "...").concat(item.txHash.slice(-10))) + " ")])];
      }
    }])
  })], 1), _vm.hasMoreItems ? _c('div', {
    ref: "loadingIndicator",
    staticClass: "text-center py-3 loading-indicator"
  }, [_c('vue-loaders-ball-beat', {
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "0.7"
    }
  })], 1) : _vm._e(), !_vm.hasMoreItems && _vm.visibleTransactions.length > 0 ? _c('div', {
    staticClass: "text-center py-3 end-message"
  }, [_vm._v(" No more transactions to load ")]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
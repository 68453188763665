<template>
  <div class="metrics-grid">
    <div class="metric-card">
      <h3>Total TVS</h3>
      <p class="metric-value">${{ formatNumber(tvsSum) }}</p>
      <!-- <p class="metric-change" :class="{ positive: tvsDailyChange >= 0 }">
        {{ tvsDailyChange >= 0 ? '+' : '' }}{{ tvsDailyChange }}%
      </p> -->
    </div>
    <!-- <div class="metric-card">
      <h3>Active Providers</h3>
      <p class="metric-value">{{ providers.length }}</p>
      <p class="metric-subtitle">Last 24h</p>
    </div> -->
    <div class="metric-card">
      <h3>Number of assets </h3>
      <p class="metric-value">{{ formatNumber(Object.keys(tokens).length + 300) }}</p>
    </div>
    <div class="metric-card">
      <h3>Users</h3>
      <p class="metric-value">{{ formatNumber(121) }}</p>
    </div>
    <div class="metric-card">
      <h3>Supported networks</h3>
      <p class="metric-value">{{ formatNumber(Object.keys(networks).length+31) }}</p>
    </div>
    <!-- <div class="metric-card">
      <h3>Number of protocols used to calculate TVS</h3>
      <p class="metric-value">{{ formatNumber(Object.keys(tvsPerClient).length) }}</p>
      <p class="metric-subtitle">Out of {{ formatNumber(Object.keys(clientsTvl).length) }} clients listed on DefiLama</p>
    </div> -->
    <!-- <div class="metric-card">
      <h3>Avg Response Time</h3>
      <p class="metric-value">{{ avgResponseTime }}s</p>
      <p class="metric-subtitle">99.9% Uptime</p>
    </div> -->
  </div>
</template>

<script>
import tokens from '@/config/tokens.json'
import networks from '@/data/networks.json'
import providers from './../DataProviders/nodes.json'
export default {
  name: 'KeyMetricsGrid',
  data(){
    return{
      providers,
      tokens,
      networks
    }
  },
  props: {
    tvsPerClient: {
      type: Object,
      required: true
    },
    clientsTvl: {
      type: Object,
      required: true
    },
    tvsSum: {
      type: Number,
      required: true
    },
    tvsDailyChange: {
      type: Number,
      required: true
    },
    activeProviders: {
      type: Number,
      required: true
    },
    dailyTransactions: {
      type: Number,
      required: true
    },
    dailyVolume: {
      type: Number,
      required: true
    },
    avgResponseTime: {
      type: Number,
      required: true
    }
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(num);
    }
  }
};
</script>
<template>
  <div class="clients-section">
    <div class="clients-section__header">
      <h2 class="clients-section__title">Tvs Clients</h2>
      <div class="clients-section__controls">
        <select v-model="sortOrder" class="clients-section__sort">
          <option value="desc">Highest TVL First</option>
          <option value="asc">Lowest TVL First</option>
        </select>
        <!-- <label class="clients-section__view-toggle">
          <input type="checkbox" v-model="isListView" />
          <span class="toggle"></span>
          List View
        </label> -->
      </div>
    </div>

    <!-- Grid View -->
    <div v-if="!isListView" class="clients-grid">
      <div v-for="client in sortedClients" 
           :key="client.name" 
           class="client-card">
        <div class="client-card__logo"
             :style="{ backgroundColor: getClientColor(client.name) }">
          {{ client.name.charAt(0) }}
        </div>
        <div class="client-card__info">
          <h3 class="client-card__name">{{ client.name }}</h3>
          <p class="client-card__value">${{ formatNumber(client.value) }}</p>
          <p class="client-card__percentage">{{ calculatePercentage(client.value) }}% of total</p>
        </div>
      </div>
    </div>

    <!-- List View -->
    <div v-else class="clients-list">
      <div class="clients-list__header">
        <div class="clients-list__col">Client</div>
        <div class="clients-list__col">TVL Value</div>
        <div class="clients-list__col">Percentage</div>
      </div>
      <div v-for="client in sortedClients" 
           :key="client.name" 
           class="clients-list__row">
        <div class="clients-list__col">
          <span class="clients-list__color-dot"
                :style="{ backgroundColor: getClientColor(client.name) }">
          </span>
          {{ client.name }}
        </div>
        <div class="clients-list__col">${{ formatNumber(client.value) }}</div>
        <div class="clients-list__col">{{ calculatePercentage(client.value) }}%</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientList',
  props: {
    tvsPerClient: {
      type: Object,
      required: true
    },
    colorPalette: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      sortOrder: 'desc',
      isListView: true,
      clientColors: {}
    };
  },
  computed: {
    totalTvs() {
      return Object.values(this.tvsPerClient).reduce((sum, value) => sum + value, 0);
    },
    sortedClients() {
      return Object.entries(this.tvsPerClient)
        .map(([name, value]) => ({ name, value }))
        .sort((a, b) => {
          const comparison = b.value - a.value;
          return this.sortOrder === 'desc' ? comparison : -comparison;
        });
    }
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(num);
    },
    calculatePercentage(value) {
      return ((value / this.totalTvs) * 100).toFixed(2);
    },
    getClientColor(clientName) {
      if (!this.clientColors[clientName]) {
        const index = Object.keys(this.clientColors).length % this.colorPalette.length;
        this.clientColors[clientName] = this.colorPalette[index];
      }
      return this.clientColors[clientName];
    }
  }
};
</script>
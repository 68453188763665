var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pull-model"
  }, [_vm._m(0), _c('b-table', {
    attrs: {
      "id": "data-providers-table",
      "stacked": "md",
      "sort-icon-left": "",
      "hover": "",
      "items": _vm.filteredNodes,
      "fields": _vm.fields
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('div', {
          staticClass: "flex items-center"
        }, [_c('img', {
          staticClass: "w-6 h-6 feeds__token-image",
          attrs: {
            "src": item.logo
          }
        }), _c('span', {
          staticClass: "ml-3"
        }, [_vm._v(_vm._s(item.name))])])];
      }
    }, {
      key: "cell(evmAddress)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.evmAddress ? _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" " + _vm._s(_vm.truncateString(item.evmAddress)) + " "), _c('CopyToClipboard', {
          attrs: {
            "copy-text": "Copy EVM address",
            "value": item.evmAddress
          }
        })], 1) : _c('div', {
          staticClass: "text-center"
        }, [_vm._v(" Coming soon ")])];
      }
    }])
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pull-model__view-details"
  }, [_c('h1', [_vm._v("RedStone Production Nodes")]), _c('p', [_vm._v(" Production nodes from RedStone network that provide real-time price feeds ")])]);

}]

export { render, staticRenderFns }
<template>
  <div class="chart-card">
    <h3>Top 10 Clients by TVS</h3>
    <apexchart
      type="bar"
      height="400"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'TopClientsChart',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    tvsPerClient: {
      type: Object,
      required: true
    },
    chartTheme: {
      type: Object,
      required: true
    },
    colorPalette: {
      type: Array,
      required: true
    }
  },
  computed: {
    sortedClients() {
      return Object.entries(this.tvsPerClient)
        .sort(([, a], [, b]) => b - a)
        .slice(0, 10);
    },
    chartOptions() {
      const categories = this.sortedClients.map(([name]) => name);

      return {
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
          background: '#13111b',
          foreColor: '#fff',
          animations: {
            enabled: true,
            easing: 'easeinout',
            speed: 800,
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        theme: this.chartTheme,
        legend: {
          show: false
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '15%',
            distributed: true,
            borderRadius: 20,
            borderRadiusApplication: 'end'
          }
        },
        states: {
          hover: {
            filter: {
              type: 'none'
            }
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            type: 'horizontal',
            shadeIntensity: 0.8,
            gradientToColors: ['#ff1f1f'],
            opacityFrom: 1,
            opacityTo: 0.6,
            stops: [0, 100]
          }
        },
        markers: {
          size: [0, 6],
          colors: ['#ff1f1f'],
          strokeColors: '#fff',
          strokeWidth: 2,
          hover: {
            size: 8,
            sizeOffset: 3
          },
          discrete: [{
            seriesIndex: 0,
            dataPointIndex: -1,
            fillColor: '#ff1f1f',
            strokeColor: '#fff',
            size: 6,
            shape: "circle"
          }]
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          labels: {
            formatter: (value) => this.formatNumber(value),
            style: {
              colors: '#fff'
            }
          },
          axisBorder: {
            show: true,
            color: '#1d1927'
          },
          axisTicks: {
            show: true,
            color: '#1d1927'
          }
        },
        yaxis: {
          type: 'category',
          labels: {
            style: {
              colors: Array(categories.length).fill('#fff'),
              fontSize: '12px'
            },
            align: 'left'
          }
        },
        grid: {
          show: true,
          borderColor: '#1d1927',
          strokeDashArray: 5,
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: false
            }
          },
          padding: {
            left: 10
          }
        },
        tooltip: {
          enabled: true,
          theme: 'dark',
          marker: {
            show: true
          },
          y: {
            title: {
              formatter: () => 'TVS: '
            },
            formatter: (value) => this.formatNumber(value)
          }
        }
      };
    },
    series() {
      return [{
        name: 'TVS',
        data: this.sortedClients.map(([name, value], index) => ({
          x: name,
          y: value,
          markers: {
            size: 6,
            fillColor: '#ff1f1f',
            strokeColor: '#fff',
            radius: 2
          }
        }))
      }];
    }
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(num);
    }
  }
};
</script>
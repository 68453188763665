import {
  hexToDate,
  parseUnixTime,
  getTimeUntilNextHeartbeat,
  timeUntilDate,
  findNearestCronDate,
} from "@/core/timeHelpers";
import {
  format,
  formatDuration,
  parseISO,
  formatDistanceToNow,
} from "date-fns";
import cronstrue from "cronstrue";
import networks from "@/data/networks.json";
import tokens from "@/config/tokens.json";

export const images = Object.keys(tokens).map((token) => ({
  token,
  ...tokens[token],
}));

const excludedFeeds = [
  { feed: "ETHx", chainId: 1 },
  { feed: "pumpBTC/BTC", chainId: 21000000, multifeed: true },
  { feed: "USDe", chainId: 21000000, multifeed: true },
  { feed: "pumpBTC/BTC", chainId: 21000001, multifeed: true },
  { feed: "USDe", chainId: 21000001, multifeed: true },
  { feed: "STONE", chainId: 56, multifeed: false },
];

const MULTIFEED_NETWORKS = {
  BERACHAIN: 80094,
};

// Filter functions
const isMultifeedOverride = (feed) => 
  Object.values(MULTIFEED_NETWORKS).includes(feed.networkId) && 
  feed.layerId?.toLowerCase().includes('multifeed');

const isExcludedFeed = (feed, excluded) => 
  excluded.feed === feed.feedId && 
  excluded.chainId === feed.networkId &&
  (excluded.multifeed || !feed.layerId?.toLowerCase().includes('multifeed'));

const hasDuplicateInMultifeeds = (feed, multifeeds) =>
  multifeeds.some(multifeed => 
    multifeed.feedId === feed.feedId && 
    multifeed.networkId === feed.networkId
  );

// Transform functions
const transformFeedData = (item) => {
  const answerCurrency = item.feedId.split("/")[1];
  
  return {
    // Basic info
    feed: formatFeedId(item.feedId),
    token: item.feedId,
    layer_id: item.feedId,
    crypto_token: removeSeparators(item.feedId),
    
    // Addresses and IDs
    contract_address: item.contractAddress,
    feed_address: item.feedAddress,
    relayerId: item.layerId,
    
    // Values and calculations
    answer: parseToDecimal(item.value),
    contractAnswer: parseToCurrency(parseToDecimal(item.value), answerCurrency, item.feedId),
    apiAnswer: parseToCurrency(item.apiValues?.value * 100000000, answerCurrency, item.feedId),
    denomination: resolveDenomination(item.feedId),
    
    // Metadata
    timestamp: getTimestampValue(item),
    heartbeat: getHeartbeatValue(item),
    deviation: getDeviationValue(item),
    popularity: getPopularityValue(item),
  
    // Feed types
    isFundamentalFeed: isFundamentalFeed(item.feedId),
    isTwap30: isTwapFeed(item.feedId, 30),
    isTwap60: isTwapFeed(item.feedId, 60),
    
    // UI elements
    token_image: getTokenImage(item.feedId),
    heartbeatTitle: heartbeatTitle(item),
    
    // Technical details
    cron: item.triggers.cron,
    loaders: item.loaders,
    heartbeatInterval: resolveTimeSinceLastUpdateInMilliseconds(item),
    apiValues: item.apiValues,
    
    // Time formatting
    updateTime: formatUpdateTime(item.apiValues?.timestamp),
    humanUpdateTime: formatHumanUpdateTime(item),
    
    // Network info
    network: {
      id: item.networkId,
      name: findNetworkName(item.networkId),
      image: findNetworkImage(item.networkId)
    },
    explorer: {
      name: findNetworkName(item.networkId),
      explorerUrl: findExplorerBaseUrl(item.networkId),
      prefix: item.explorerPrefix,
      getParameters: findExplorerQueryParams(item.networkId),
      txSlug: item.contractType.includes('radix') ? 'transaction' : 'tx'
    }
  };
};

const findExplorerBaseUrl = (networkId) => {
  const hasExplorer = Object.values(networks).some(
    (network) => network.chainId === networkId
  );
  if (!hasExplorer) console.warn("Missing explorer for chain:", networkId);
  
  const explorerUrl = Object.values(networks).find(
    (network) => network.chainId === networkId
  )?.explorerUrl;
  
  // Remove any GET parameters from the URL
  if (explorerUrl) {
    return explorerUrl.split('?')[0];
  }
  
  return '';
};

const findExplorerQueryParams = (networkId) => {
  const hasExplorer = Object.values(networks).some(
    (network) => network.chainId === networkId
  );
  if (!hasExplorer) return '';
  
  const explorerUrl = Object.values(networks).find(
    (network) => network.chainId === networkId
  )?.explorerUrl;
  
  // Extract query parameters if present
  if (explorerUrl && explorerUrl.includes('?')) {
    return explorerUrl.split('?')[1];
  }
  
  return '';
};

// Helper functions
const formatFeedId = (feedId) => 
  hasSlash(feedId) ? feedId : `${feedId}/${resolveDenomination(feedId)}`;

const formatUpdateTime = (timestamp) =>
  timestamp ? format(parseISO(timestamp), "MMMM d, yyyy h:mm a zzz") : "";

const formatHumanUpdateTime = (item) => {
  if (item.apiValues?.timestamp) {
    return formatDistanceToNow(parseISO(item.apiValues.timestamp), {
      addSuffix: true
    });
  }
  return getTimestampValue(item).parsed + ' ago' || "";
};

// Main export functions
export const toUrlParam = (string) =>
  string.toLowerCase().replace(" ", "-").replace("/", "--");

export const mapFeedsData = (storeFeedsArray) => {
  if (!storeFeedsArray?.length) return [];

  // Separate and filter feeds
  const multifeeds = storeFeedsArray.filter(isMultifeedOverride);
  const regularFeeds = storeFeedsArray.filter(feed => !isMultifeedOverride(feed));

  const filteredMultifeeds = multifeeds.filter(
    feed => !excludedFeeds.some(excluded => isExcludedFeed(feed, excluded))
  );

  const filteredRegularFeeds = regularFeeds.filter(feed => 
    !excludedFeeds.some(excluded => isExcludedFeed(feed, excluded)) &&
    !hasDuplicateInMultifeeds(feed, filteredMultifeeds)
  );

  // Transform filtered feeds
  return [...filteredMultifeeds, ...filteredRegularFeeds].map(transformFeedData);
};


const resolveTimestampForHeartbeat = (item) => {
  if (item?.apiValues?.timestamp != null) {
    const unixTimestamp = Math.floor(
      new Date(item.apiValues.timestamp).getTime() / 1000
    );
    return "0x" + unixTimestamp.toString(16).padStart(8, "0");
  } else if (item?.timestamp) {
    return item.timestamp;
  } else {
    return (
      "0x" +
      Math.floor(Date.now() / 1000)
        .toString(16)
        .padStart(8, "0")
    );
  }
};

export const clearFeedName = (feedId) =>
  feedId
    .replace("_FUNDAMENTAL", "")
    .replace("-TWAP-30", "")
    .replace("-TWAP-60", "")
    .replace("_RATE_PROVIDER", "");

const resolveDenomination = (token) => {
  return denominationCustomMap?.[token] || token.split("/")[1] || "USD";
};

const getHeartbeatValue = (item) =>
  getTimeUntilNextHeartbeat(
    resolveTimestampForHeartbeat(item),
    resolveTimeSinceLastUpdateInMilliseconds(item)
  ) || JSON.stringify(item.triggers.cron);

const getTimestampValue = (item) => ({
  parsed: parseUnixTime(item.timestamp),
  raw: item.timestamp,
  date: hexToDate(item.timestamp),
});

const getDeviationValue = (item) =>
  resolveDeviationPercentage(item) != "n/a"
    ? resolveDeviationPercentage(item) + "%"
    : "n/a";

const getPopularityValue = (item) =>
  `${networkOrder().findIndex((network) => item.networkId === network.chainId)}_${cryptoOrder().findIndex((crypto) => removeSeparators(item.feedId) === crypto.token)}`;

export const parseToDecimal = (hexValue) => {
  hexValue = hexValue?.replace(/^0x/, "");
  return parseInt(hexValue, 16);
};

export const hasSlash = (string) => {
  return string.indexOf("/") >= 0;
};

const removeSeparators = (string) => {
  const noSlash = string.split("/")[0];
  const noUnder = noSlash.split("_")[0];
  const noSeparators = noUnder.split("-")[0];
  return noSeparators;
};

export const findNetworkName = (networkId) => {
  return Object.values(networks).find(
    (network) => network.chainId === networkId
  ).name;
};

export const findNetworkImage = (networkId) => {
  return Object.values(networks).find(
    (network) => network.chainId === networkId
  ).iconUrl;
};

const findNetwork = (networkId) => {
  return Object.values(networks).find(
    (network) => network.chainId === networkId
  );
};


const msToTime = (ms) => {
  const totalSeconds = Math.floor(ms / 1000);
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  if (hours > 0) {
    return formatDuration({ hours, minutes }, { format: ["hours", "minutes"] });
  } else if (minutes > 0) {
    return formatDuration(
      { minutes, seconds },
      { format: ["minutes", "seconds"] }
    );
  } else {
    return formatDuration({ seconds }, { format: ["seconds"] });
  }
};

const transformHexString = (str) => {
  if (str == null) return "no data";
  if (str?.length <= 10) return str;
  return `${str?.slice(0, 7)} . . . ${str?.slice(-4)}`;
};

export const getTokenImage = (token) => {
  const idealMatchImg = images.find((image) => token === image.token);
  const secondMatch = images.find(
    (image) => token.split("/")[0] === image.token
  );
  return (
    idealMatchImg ||
    secondMatch || {
      name: "placeholder",
      logoURI:
        "https://raw.githubusercontent.com/redstone-finance/redstone-images/main/symbols/placeholder.png",
      token: "placeholder",
    }
  );
};

export const createNetworkUrlParam = (networkName) => {
  return networkName.toLowerCase().replace(" ", "-");
};

export const processTokenData = (data) => {
  const tokenMap = new Map();
  data.forEach(({ token, network }) => {
    const tokens = token.includes("/") ? token.split("/") : [token];
    tokens.forEach((t) => {
      if (!tokenMap.has(network)) {
        tokenMap.set(network, new Set());
      }
      tokenMap.get(network).add(t);
    });
  });
  const processedData = [];
  for (const [network, tokens] of tokenMap.entries()) {
    tokens.forEach((token) => {
      processedData.push({ token, network });
    });
  }
  return processedData;
};

const resolveDeviationPercentage = (item) => {
  const triggerOverride = item.overrides.filter(
    (override) => override.value !== undefined
  );
  const deviationPercentage =
    triggerOverride.length > 0 && triggerOverride[0].value?.deviationPercentage
      ? triggerOverride[0].value.deviationPercentage
      : item.triggers.deviationPercentage;

  return deviationPercentage || "n/a";
};

const heartbeatTitle = (item) => {
  const heartbeat = resolveTimeSinceLastUpdateInMilliseconds(item);
  const crons = item.triggers.cron;
  if (crons) {
    return crons.map((cron) => cronstrue.toString(cron)).join(", ");
  } else {
    return msToTime(heartbeat);
  }
};

const resolveTimeSinceLastUpdateInMilliseconds = (item) => {
  const triggerOverride = item.overrides.filter(
    (override) => override.value !== undefined
  );
  const timeSinceLastUpdateInMilliseconds =
    triggerOverride.length > 0 &&
    triggerOverride[0]?.type === "full" &&
    triggerOverride[0]?.value?.timeSinceLastUpdateInMilliseconds !== undefined
      ? triggerOverride[0].value.timeSinceLastUpdateInMilliseconds
      : item.triggers.timeSinceLastUpdateInMilliseconds;

  return timeSinceLastUpdateInMilliseconds;
};

export const nearestCron = (cronString) => {
  if (cronString == null) {
    return 0;
  }
  try {
    const parsedCron = JSON.parse(cronString);
    const nearestDate = findNearestCronDate(parsedCron);
    const timeUntil = timeUntilDate(nearestDate);
    return timeUntil;
  } catch (error) {
    console.error("Error parsing cron string:", error);
    return "Invalid cron";
  }
};

const fundamentalFeedIdsParts = [
  "_FUNDAMENTAL",
  "_RATE_PROVIDER",
  "CRV",
  "3Crv",
  "sUSDe",
  "wstETH/stETH",
  "wUSDM",
  "apxETH",
  "sDAI",
  "STONE",
];

export const isFundamentalFeed = (feedId) =>
  fundamentalFeedIdsParts.some((part) => feedId.includes(part));

export const isTwapFeed = (feedId, twapValue) =>
  feedId.includes(`TWAP-${twapValue}`);

export const heartbeatIsNumber = (value) => {
  return !isNaN(value);
};

export const denominationCustomMap = {
  wstETH_FUNDAMENTAL: "USD",
  uniETH_FUNDAMENTAL: "USD",
  deUSD_FUNDAMENTAL: "USD",
  pufETH_FUNDAMENTAL: "ETH",
  pzETH_FUNDAMENTAL: "ETH",
  mETH_FUNDAMENTAL: "ETH",
  LBTC_FUNDAMENTAL: "BTC",
  ssETH_FUNDAMENTAL: "ETH",
  SUSDz_FUNDAMENTAL: "USDz",
  ETH_CLE: "ETH",
  ETH_ELE: "ETH",
  "ETH_CLE+": "ETH",
  sUSDe_RATE_PROVIDER: "USDe",
  SolvBTC_MERLIN: "USD",
  "SolvBTC.BBN": "USD",
  SolvBTC_BNB: "USD",
  BBTC: "USD",
  BBUSD: "USD",
  "PREMIA-TWAP-60": "USD",
  "ezETH-TWAP-60": "USD",
  "USDB-TWAP-30": "USD",
  "SolvBTC_MERLIN/BTC-TWAP-60": "BTC",
  weETH_FUNDAMENTAL: "ETH",
  apxETH: "USD",
  "ETH+": "USD",
  sfrxETH: "USD",
  "sfrxETH/ETH": "ETH",
  "eBTC/WBTC": "BTC",
  pumpBTC: "BTC",
  pumpBTC_FUNDAMENTAL: "BTC",
};

export const formatToCurrency = (value) => {
  const formatterOptions = {
    style: "currency",
    currency: "USD",
  };
  if (value >= 1) {
    formatterOptions.minimumFractionDigits = 3;
    formatterOptions.maximumFractionDigits = 3;
  } else {
    formatterOptions.notation = "standard";
    formatterOptions.minimumSignificantDigits = 4;
    formatterOptions.maximumSignificantDigits = 4;
  }
  const formatter = new Intl.NumberFormat("en-US", formatterOptions);
  return formatter.format(value);
};

export const formatPriceWithoutCurrency = (
  value,
  sUSDe_RATE = false,
  bigDec = false
) => {
  const decValue = bigDec ? 8 : 0;
  return formatToCurrency(
    value / Math.pow(10, sUSDe_RATE ? 10 : decValue)
  ).replace("$", "");
};

export const parseToCurrency = (
  decimalValue,
  currency,
  token,
  bigDec = false
) => {
  const decValue = bigDec ? 8 : 0;
  const sUSDe_RATE = token === "sUSDe_RATE_PROVIDER";
  const value = decimalValue / Math.pow(10, sUSDe_RATE ? 18 : decValue);
  const customDenomination = denominationCustomMap?.[token];
  const finalCurrency = customDenomination || currency;
  let formattedValue = formatToCurrency(value);
  if (finalCurrency && currency !== "USD") {
    switch (finalCurrency) {
      case "EUR":
        formattedValue = formattedValue.replace("$", "<span>€</span>");
        break;
      case "ETH":
        formattedValue = formattedValue.replace("$", "<span>Ξ</span>");
        break;
      case "BRL":
        formattedValue = formattedValue.replace("$", "<span>R$</span>");
        break;
      case "GBP":
        formattedValue = formattedValue.replace("$", "<span>£</span>");
        break;
      case "BTC":
        formattedValue = formattedValue.replace("$", "<span>₿</span>");
        break;
      case "USD":
        formattedValue = formattedValue.replace("$", "<span>$</span>");
        break;
      case "USDe":
        formattedValue = formattedValue.replace("$", "") + "<span>USDe</span>";
        break;
      case "percentage":
        formattedValue = formattedValue.replace("$", "") + "<span>%</span>";
        break;
      default:
        formattedValue =
          formattedValue.replace("$", "") + `<span>${currency}</span>`;
        break;
    }
  }
  return formattedValue;
};

export const currencySymbolMap = {
  USD: "$",
  EUR: "€",
  ETH: "Ξ",
  BRL: "R$",
  GBP: "£",
  BTC: "₿",
  USDe: "USDe",
  percentage: "%",
};

const networkOrder = () => {
  return Object.values(networks);
};
const cryptoOrder = () => {
  return Object.values(images);
};

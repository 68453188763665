import axios from "axios";
import { ethers } from "ethers";
import { isEmpty, isObject } from "lodash";
import Vue from "vue";
import networks from "@/data/networks";

function stringToBytes32(str) {
  const bytes = ethers.utils.toUtf8Bytes(str);
  const truncatedBytes = bytes.slice(0, 32);
  let hexString = ethers.utils.hexlify(truncatedBytes).slice(2);
  hexString = hexString.padEnd(64, "0");
  return "0x" + hexString;
}

const RELAYERS_SCHEMA_URL =
  "https://p6s64pjzub.execute-api.eu-west-1.amazonaws.com/dev/execute";
const RELAYERS_VALUES_URL = "https://api.redstone.finance/feeds-answers-update";
const CONTRACTS_ABI_DEFINITION = [
  "function getBlockTimestampFromLatestUpdate() view returns (uint256)",
  "function getValueForDataFeed(bytes32 dataFeedId) view returns (uint256)",
];
const CONTRACTS_ABI_DEFINITION_MULTIFEED = [
  "function getBlockTimestampFromLatestUpdate(bytes32 dataFeedId) view returns (uint256)",
  "function getValueForDataFeed(bytes32 dataFeedId) view returns (uint256)",
];

// Function to determine explorer prefix based on network type
function getExplorerPrefix(adapterContractType, networkId) {
  // Sui and Movement use object format
  if (adapterContractType?.includes('sui') || 
      adapterContractType?.includes('movement') ||
      networkId === 250) {
    return 'object';
  }
  
  // Radix uses component format
  if (adapterContractType?.includes('radix') || networkId === 'radix/2' || networkId === 'radix/1') {
    return 'component';
  }
  
  // Default to EVM format
  return 'address';
}

// Check if a chainId is a non-EVM prefixed chainId
function isNonEvmChainId(chainId) {
  return typeof chainId === 'string' && (
    chainId.startsWith('radix/') ||
    chainId.startsWith('sui/') ||
    chainId.startsWith('movement/')
  );
}

// Extract original numeric chainId from prefixed chainId
function getNumericChainId(chainId) {
  if (isNonEvmChainId(chainId)) {
    return parseInt(chainId.split('/')[1]);
  }
  return chainId;
}

// Find network with handling for prefixed chainIds
function findNetworkByChainId(chainId) {
  return Object.values(networks).find(
    (network) => network.chainId === chainId
  );
}

export const relayerMapper = (
  relayerSchema,
  relayersDetails,
  relayersValues
) => {
  return Object.keys(relayerSchema).flatMap((key) => {
    const layer = relayerSchema[key];
    const network = findNetworkByChainId(layer.chain.id);
    const networkMapped = !!network;

    if (!networkMapped) {
      console.warn(
        `Warning: Network not mapped for chain ID ${layer.chain.id}`
      );
      return [];
    }

    return Object.keys(layer.priceFeeds).map((feedId) => {
      const itemKey = `${key}_${feedId}`;
      const keyFeedTimestamp = relayersDetails[itemKey]?.blockTimestamp;
      const keyFeedValue = relayersDetails[itemKey]?.dataFeed;
      
      // Check if this is a non-EVM network
      const isNonEvmNetwork = layer.adapterContractType?.includes("sui") || 
                              layer.adapterContractType?.includes("radix") ||
                              layer.adapterContractType?.includes("movement") ||
                              isNonEvmChainId(layer.chain.id);
      
      // For non-EVM networks, we use the adapter contract directly instead of priceFeedAddress
      const feedAddress = isNonEvmNetwork && layer.priceFeeds[feedId]?.priceFeedAddress == null
        ? layer.adapterContract 
        : (isObject(layer.priceFeeds[feedId]) 
            ? layer.priceFeeds[feedId].priceFeedAddress 
            : layer.priceFeeds[feedId]);
      
      return {
        routeNetwork: network.name.toLowerCase().replace(" ", "-"),
        routeToken: feedId.toLowerCase(),
        networkId: layer.chain.id,
        feedId: feedId,
        overrides: [
          {
            type: "deviation",
            value: layer.updateTriggers.priceFeedsDeviationOverrides?.[feedId],
          },
          {
            type: "full",
            value: layer.priceFeeds[feedId]?.updateTriggersOverrides,
          },
        ],
        contractAddress: layer.adapterContract,
        adapterContractPackageId: layer.adapterContractPackageId, // Added for non-EVM networks
        feedAddress: feedAddress,
        triggers: layer.updateTriggers,
        layerId: key,
        timestamp: keyFeedTimestamp || null,
        value: keyFeedValue || null,
        loaders: relayersDetails[itemKey]?.loaders,
        apiValues: relayersValues?.[key]?.[feedId],
        contractType: layer.adapterContractType || "standard",
        explorerPrefix: getExplorerPrefix(layer.adapterContractType, layer.chain.id),
        isNonEvm: isNonEvmNetwork
      };
    });
  });
};

export default {
  namespaced: true,
  state: {
    relayerSchema: {},
    smartContracts: {},
    relayersDetails: {},
    relayersValues: {},
  },
  mutations: {
    assignRelayerSchema(state, schema) {
      state.relayerSchema = schema;
    },
    assignRelayerValues(state, values) {
      state.relayersValues = values;
    },
    assignCreatedSmartContract(state, { contract, layerId }) {
      state.smartContracts[layerId] = contract;
    },
    assignSuiConnection(state, { connected, layerId }) {
      state.suiConnections[layerId] = connected;
    },
    assignRelayerDetails(state, { key, layerId, data }) {
      if (state.relayersDetails[layerId]) {
        state.relayersDetails[layerId][key] = data;
      }
    },
    disableLoaderMutation(state, { loaderId, layerId }) {
      if (state.relayersDetails[layerId]) {
        state.relayersDetails[layerId].loaders[loaderId] = false;
      }
    },
  },
  getters: {
    allLoadersComplete: (state) => {
      return Object.values(state.relayersDetails).every((relayer) => {
        return Object.values(relayer.loaders).every(
          (loader) => loader === false
        );
      });
    },
    getSmartContractByLayerId: (state) => (layerId) => {
      const contract = state.smartContracts[layerId];
      if (contract == null) {
        console.warn("No contract assigned to layer id:", layerId);
      }
      return contract;
    },
    isSuiNetworkConnected: (state) => (layerId) => {
      return !!state.suiConnections[layerId];
    },
    hasMultipleFeeds: (state) => (layerId) => {
      return state.relayerSchema[layerId].priceFeeds.length > 1;
    },
    combinedFeedsWithDetailsArray(state, getters) {
      return relayerMapper(
        state.relayerSchema,
        state.relayersDetails,
        state.relayersValues
      );
    },
  },
  actions: {
    createSmartContract(
      { commit, state },
      { layerId, contractAddress, chainId, contractType }
    ) {
      const numericChainId = getNumericChainId(chainId);
      const contractNetwork = Object.values(networks).find(
        (network) => network.chainId === numericChainId
      );
      
      const provider = new ethers.providers.JsonRpcProvider(
        contractNetwork?.rpcUrl
      );
      const abi =
        contractType === "multi-feed" || contractType === "mento"
          ? CONTRACTS_ABI_DEFINITION_MULTIFEED
          : CONTRACTS_ABI_DEFINITION;
      const contract = new ethers.Contract(contractAddress, abi, provider);
      commit("assignCreatedSmartContract", { contract, layerId });
    },
    async fetchDataFeedId({ commit, state }, layerId) {
      const api = this.getters["feeds/getSmartContractByLayerId"](layerId);
      try {
        var id = await api.getDataFeedId();
      } catch (error) {
        console.warn("Single data feed id contract fetch error", layerId);
      }
      try {
        var ids = await api.getDataFeedIds();
      } catch (error) {
        this.dispatch("feeds/disableLoader", {
          layerId,
          loaderId: "feedDataValue",
        });
      }
      commit("assignRelayerDetails", {
        key: "feedId",
        layerId,
        data: id || ids,
      });
      this.dispatch("feeds/disableLoader", { layerId, loaderId: "feedId" });
    },
    async fetchBlockTimeStampMultifeed({ commit, state }, { layerId, feedId }) {
      if (
        state.relayersDetails[`${layerId}_${feedId}`].loaders.blockTimestamp ===
        false
      )
        return;
      const method =
        state.relayerSchema[layerId]?.adapterContractType === "multi-feed"
          ? this.getters["feeds/getSmartContractByLayerId"](
              layerId
            ).getBlockTimestampFromLatestUpdate(stringToBytes32(feedId))
          : this.getters["feeds/getSmartContractByLayerId"](
              layerId
            ).getBlockTimestampFromLatestUpdate();
      method
        .then((timestamp) => {
          commit("assignRelayerDetails", {
            key: "blockTimestamp",
            layerId: `${layerId}_${feedId}`,
            data: timestamp?._hex,
          });
        })
        .catch((error) => {
          console.warn("Contract timestamp fetching error", layerId);
        })
        .finally(() => {
          this.dispatch("feeds/disableLoader", {
            layerId: `${layerId}_${feedId}`,
            loaderId: "blockTimestamp",
          });
        });
    },
    async fetchValueForDataFeedMultifeed(
      { commit, state },
      { layerId, feedId }
    ) {
      if (
        state.relayersDetails[`${layerId}_${feedId}`].loaders.feedDataValue ===
        false
      )
        return;
      this.getters["feeds/getSmartContractByLayerId"](layerId)
        .getValueForDataFeed(stringToBytes32(feedId))
        .then((value) => {
          commit("assignRelayerDetails", {
            key: "dataFeed",
            layerId: `${layerId}_${feedId}`,
            data: value?._hex,
          });
        })
        .catch((error) => {
          console.warn("Contract timestamp fetching error", layerId);
        })
        .finally(() => {
          this.dispatch("feeds/disableLoader", {
            layerId: `${layerId}_${feedId}`,
            loaderId: "feedDataValue",
          });
        });
    },
    disableLoader({ commit }, { layerId, loaderId }) {
      commit("disableLoaderMutation", { layerId, loaderId });
    },
    initializeLayerDetails({ state }) {
      Object.keys(state.relayerSchema).forEach((layerId) => {
        Object.keys(state.relayerSchema[layerId].priceFeeds).forEach((key) => {
          Vue.set(state.relayersDetails, `${layerId}_${key}`, {
            feedId: null,
            blockTimestamp: null,
            dataFeed: null,
            loaders: {
              feedDataValue: true,
              blockTimestamp: true,
            },
          });
        });
      });
    },
    async fetchRelayerSchema({ commit, state }) {
      if (!isEmpty(state.relayerSchema)) return;
      const { data } = await axios.get(RELAYERS_SCHEMA_URL);
      
      // Process data to match our expected format
      const processedData = { 
        ...data.standard, 
        ...data.multifeed
      };
      
      // Process nonEvm data with prefixed chainIds
      if (data.nonEvm) {
        Object.keys(data.nonEvm).forEach(key => {
          const nonEvmData = {...data.nonEvm[key]};
          
          // Add prefix to chainId based on adapter contract type
          if (nonEvmData.adapterContractType?.includes('radix')) {
            nonEvmData.chain.id = `radix/${nonEvmData.chain.id}`;
          } else if (nonEvmData.adapterContractType?.includes('sui')) {
            nonEvmData.chain.id = `sui/${nonEvmData.chain.id}`;
          } else if (nonEvmData.adapterContractType?.includes('movement')) {
            nonEvmData.chain.id = `movement/${nonEvmData.chain.id}`;
          }
          
          processedData[key] = nonEvmData;
        });
      }
      
      commit("assignRelayerSchema", processedData);
      
      if (isEmpty(state.relayersDetails)) {
        this.dispatch("feeds/initializeLayerDetails");
      }
    },
    async fetchRelayerValues({ commit, state }) {
      try {
        const { data } = await axios.get(RELAYERS_VALUES_URL);
        commit("assignRelayerValues", data);
      } catch (error) {
        console.warn("Relayers fetch error");
      }
    },
    async initSingleContract({ state }, { layerId, feedId }) {
      await this.dispatch("feeds/fetchRelayerSchema");
      
      const relayerData = state.relayerSchema[layerId];
      if (!relayerData) return;
      
      // Check if this is a non-EVM network
      if (relayerData.adapterContractType?.includes("sui") ||
          relayerData.adapterContractType?.includes("movement") ||
          relayerData.adapterContractType?.includes("radix") ||
          isNonEvmChainId(relayerData.chain.id)) {
        
        await this.dispatch("feeds/createSuiConnection", {
          layerId: layerId,
          moduleAddress: relayerData.adapterContract,
          packageId: relayerData.adapterContractPackageId,
          chainId: relayerData.chain.id,
        });
        
        await this.dispatch("feeds/fetchSuiBlockTimestamp", {
          layerId,
          feedId,
        });
        
        await this.dispatch("feeds/fetchSuiDataFeed", {
          layerId,
          feedId,
        });
      } else {
        // Existing EVM logic
        await this.dispatch("feeds/createSmartContract", {
          layerId: layerId,
          contractAddress: relayerData.adapterContract,
          chainId: relayerData.chain.id,
          contractType: relayerData.adapterContractType,
        });
        
        if (relayerData.adapterContractType === "multi-feed") {
          await this.dispatch("feeds/fetchBlockTimeStampMultifeed", {
            layerId,
            feedId,
          });
        } else {
          await this.dispatch("feeds/fetchBlockTimeStamp", layerId);
        }

        await this.dispatch("feeds/fetchValueForDataFeedMultifeed", {
          layerId: layerId,
          feedId: feedId,
        });
      }
    },
    async createContractAndFetchValues({ state }, { relayerId, feedId }) {
      // Check if we already have values from the API
      const hasTimestampFromApi = !!state.relayersValues?.[relayerId]?.[feedId]?.timestamp;
      const hasValueFromApi = !!state.relayersValues?.[relayerId]?.[feedId]?.value;
      
      const relayerData = state.relayerSchema[relayerId];
      if (!relayerData) return;
      
      // Only proceed with RPC calls if API values are missing
      if (!hasTimestampFromApi || !hasValueFromApi) {
        // Check if this is a non-EVM network
        if (relayerData.adapterContractType?.includes("sui") ||
            relayerData.adapterContractType?.includes("movement") ||
            relayerData.adapterContractType?.includes("radix") ||
            isNonEvmChainId(relayerData.chain.id)) {
          
          await this.dispatch("feeds/createSuiConnection", {
            layerId: relayerId,
            moduleAddress: relayerData.adapterContract,
            packageId: relayerData.adapterContractPackageId,
            chainId: relayerData.chain.id,
          });
          
          if (!hasTimestampFromApi) {
            await this.dispatch("feeds/fetchSuiBlockTimestamp", {
              layerId: relayerId,
              feedId,
            });
          }
          
          if (!hasValueFromApi) {
            await this.dispatch("feeds/fetchSuiDataFeed", {
              layerId: relayerId,
              feedId,
            });
          }
        } else {
          // EVM logic - create contract only if we need it
          await this.dispatch("feeds/createSmartContract", {
            layerId: relayerId,
            contractAddress: relayerData.adapterContract,
            chainId: relayerData.chain.id,
            contractType: relayerData.adapterContractType,
          });
          
          if (!hasTimestampFromApi) {
            await this.dispatch("feeds/fetchBlockTimeStampMultifeed", {
              layerId: relayerId,
              feedId,
            });
          }
          
          if (!hasValueFromApi) {
            await this.dispatch("feeds/fetchValueForDataFeedMultifeed", {
              layerId: relayerId,
              feedId,
            });
          }
        }
      } else {
        // If we have all values from API, just disable the loaders
        this.dispatch("feeds/disableLoader", {
          layerId: `${relayerId}_${feedId}`,
          loaderId: "blockTimestamp",
        });
        
        this.dispatch("feeds/disableLoader", {
          layerId: `${relayerId}_${feedId}`,
          loaderId: "feedDataValue",
        });
      }
    },
    async createContractAndFetchValuesForRelayer({ state }, relayerId) {
      const relayerData = state.relayerSchema[relayerId];
      if (!relayerData) return;
      
      // Get all feeds for this relayer
      const feeds = Object.keys(relayerData.priceFeeds || {});
      
      // Check if all feeds already have API values
      const allFeedsHaveApiValues = feeds.every(feedId => {
        const hasTimestampFromApi = !!state.relayersValues?.[relayerId]?.[feedId]?.timestamp;
        const hasValueFromApi = !!state.relayersValues?.[relayerId]?.[feedId]?.value;
        return hasTimestampFromApi && hasValueFromApi;
      });
      
      // If we have all values from API, just disable loaders and skip RPC calls
      if (allFeedsHaveApiValues && feeds.length > 0) {
        feeds.forEach(feedId => {
          this.dispatch("feeds/disableLoader", {
            layerId: `${relayerId}_${feedId}`,
            loaderId: "blockTimestamp",
          });
          
          this.dispatch("feeds/disableLoader", {
            layerId: `${relayerId}_${feedId}`,
            loaderId: "feedDataValue",
          });
        });
        return;
      }
      
      // Check if this is a non-EVM network
      if (relayerData.adapterContractType?.includes("sui") ||
          relayerData.adapterContractType?.includes("movement") ||
          relayerData.adapterContractType?.includes("radix") ||
          isNonEvmChainId(relayerData.chain.id)) {
        
        await this.dispatch("feeds/createSuiConnection", {
          layerId: relayerId,
          moduleAddress: relayerData.adapterContract,
          packageId: relayerData.adapterContractPackageId,
          chainId: relayerData.chain.id,
        });
        
        feeds.forEach(async (feedId) => {
          const hasTimestampFromApi = !!state.relayersValues?.[relayerId]?.[feedId]?.timestamp;
          const hasValueFromApi = !!state.relayersValues?.[relayerId]?.[feedId]?.value;
          
          if (!hasTimestampFromApi) {
            await this.dispatch("feeds/fetchSuiBlockTimestamp", {
              layerId: relayerId,
              feedId,
            });
          } else {
            this.dispatch("feeds/disableLoader", {
              layerId: `${relayerId}_${feedId}`,
              loaderId: "blockTimestamp",
            });
          }
          
          if (!hasValueFromApi) {
            await this.dispatch("feeds/fetchSuiDataFeed", {
              layerId: relayerId,
              feedId,
            });
          } else {
            this.dispatch("feeds/disableLoader", {
              layerId: `${relayerId}_${feedId}`,
              loaderId: "feedDataValue",
            });
          }
        });
      } else {
        // Create contract only if there's at least one feed missing values
        const needsContract = feeds.some(feedId => {
          const hasTimestampFromApi = !!state.relayersValues?.[relayerId]?.[feedId]?.timestamp;
          const hasValueFromApi = !!state.relayersValues?.[relayerId]?.[feedId]?.value;
          return !hasTimestampFromApi || !hasValueFromApi;
        });
        
        if (needsContract) {
          // Existing EVM logic
          await this.dispatch("feeds/createSmartContract", {
            layerId: relayerId,
            contractAddress: relayerData.adapterContract,
            chainId: relayerData.chain.id,
            contractType: relayerData.adapterContractType,
          });
        }
        
        feeds.forEach(async (feedId) => {
          const hasTimestampFromApi = !!state.relayersValues?.[relayerId]?.[feedId]?.timestamp;
          const hasValueFromApi = !!state.relayersValues?.[relayerId]?.[feedId]?.value;
          
          if (!hasTimestampFromApi) {
            await this.dispatch("feeds/fetchBlockTimeStampMultifeed", {
              layerId: relayerId,
              feedId,
            });
          } else {
            this.dispatch("feeds/disableLoader", {
              layerId: `${relayerId}_${feedId}`,
              loaderId: "blockTimestamp",
            });
          }
          
          if (!hasValueFromApi) {
            await this.dispatch("feeds/fetchValueForDataFeedMultifeed", {
              layerId: relayerId,
              feedId,
            });
          } else {
            this.dispatch("feeds/disableLoader", {
              layerId: `${relayerId}_${feedId}`,
              loaderId: "feedDataValue",
            });
          }
        });
      }
    },
    async initSchema({ state }) {
      if (!isEmpty(state.relayerSchema)) return;
      await this.dispatch("feeds/fetchRelayerSchema");
    },
    async initValues({ state }) {
      await Object.keys(state.relayerSchema).forEach(async (key) => {
        await this.dispatch(
          "feeds/createContractAndFetchValuesForRelayer",
          key
        );
      });
    },
  },
};
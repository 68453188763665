var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chart-card"
  }, [_c('h3', [_vm._v("Price Updates Distribution")]), _c('apexchart', {
    attrs: {
      "type": "bar",
      "height": "200",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
/**
 * Disclaimer Helper Utility
 * Handles localStorage interaction for route-based disclaimer acceptance
 */

const STORAGE_KEY = "acceptedDisclaimerRoutes";
const TOKENS_TO_CHECK = [
  "BUIDL_DAILY_INTEREST_ACCRUAL",
  "BUIDL_FUNDAMENTAL",
];
/**
 * Check if a disclaimer should be displayed
 * @param {string} route - The route path to check
 * @returns {boolean} - Whether the disclaimer should be displayed for this path
 */
export function isDisclaimerRequired(route) {
  try {
    const token = route
      .split("/")
      .filter((item) => item)
      .pop();
    return TOKENS_TO_CHECK.map((token) => token.toLocaleLowerCase()).includes(
      token.toLocaleLowerCase()
    );
  } catch (e) {
    console.error("Error resolving disclaimer");
    return false;
  }
}

/**
 * Check if a route has an accepted disclaimer
 * @param {string} route - The route path to check
 * @returns {boolean} - Whether the disclaimer has been accepted for this route
 */
export function isDisclaimerAccepted(route) {
  try {
    const acceptedRoutes = JSON.parse(
      localStorage.getItem(STORAGE_KEY) || "{}"
    );
    return !!acceptedRoutes[route];
  } catch (e) {
    console.error("Error reading disclaimer data from localStorage:", e);
    return false;
  }
}

/**
 * Mark a disclaimer as accepted for a specific route
 * @param {string} route - The route path to mark as accepted
 */
export function acceptDisclaimerForRoute(route) {
  try {
    const acceptedRoutes = JSON.parse(
      localStorage.getItem(STORAGE_KEY) || "{}"
    );
    acceptedRoutes[route] = true;
    localStorage.setItem(STORAGE_KEY, JSON.stringify(acceptedRoutes));
    return true;
  } catch (e) {
    console.error("Error saving disclaimer data to localStorage:", e);
    return false;
  }
}

/**
 * Clear acceptance for a specific route
 * @param {string} route - The route to clear acceptance for
 */
export function clearDisclaimerForRoute(route) {
  try {
    const acceptedRoutes = JSON.parse(
      localStorage.getItem(STORAGE_KEY) || "{}"
    );
    delete acceptedRoutes[route];
    localStorage.setItem(STORAGE_KEY, JSON.stringify(acceptedRoutes));
    return true;
  } catch (e) {
    console.error("Error clearing disclaimer data in localStorage:", e);
    return false;
  }
}

/**
 * Clear all accepted disclaimers from localStorage
 */
export function clearAllDisclaimers() {
  try {
    localStorage.removeItem(STORAGE_KEY);
    return true;
  } catch (e) {
    console.error("Error clearing all disclaimer data from localStorage:", e);
    return false;
  }
}

export default {
  isDisclaimerAccepted,
  acceptDisclaimerForRoute,
  clearDisclaimerForRoute,
  clearAllDisclaimers,
  isDisclaimerRequired,
};

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "summary-section"
  }, [_c('div', {
    staticClass: "summary-card"
  }, [_c('div', {
    staticClass: "summary-card__content"
  }, [_c('h2', {
    staticClass: "summary-card__title"
  }, [_vm._v("Total TVS")]), _c('p', {
    staticClass: "summary-card__value"
  }, [_vm._v("$" + _vm._s(_vm.formatNumber(_vm.tvsSum)))])]), _c('div', {
    staticClass: "summary-card__content"
  }, [_c('h2', {
    staticClass: "summary-card__title"
  }, [_vm._v("Projects used to calculate TVS")]), _c('p', {
    staticClass: "summary-card__value"
  }, [_vm._v(_vm._s(Object.keys(_vm.tvsPerClient).length))])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
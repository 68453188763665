<template>
  <div class="pull-model">
    <div class="pull-model__view-details">
      <h1>RedStone Production Nodes</h1>
      <p>
        Production nodes from RedStone network that provide real-time price
        feeds
      </p>
    </div>

    <b-table
      id="data-providers-table"
      stacked="md"
      sort-icon-left
      hover
      :items="filteredNodes"
      :fields="fields"
    >
      <template #cell(name)="{ item }">
        <div class="flex items-center">
          <img class="w-6 h-6 feeds__token-image" :src="item.logo" />
          <span class="ml-3">{{ item.name }}</span>
        </div>
      </template>
      <template #cell(evmAddress)="{ item }">
        <div class="text-center" v-if="item.evmAddress">
          {{ truncateString(item.evmAddress) }}
          <CopyToClipboard
            copy-text="Copy EVM address"
            :value="item.evmAddress"
          />
        </div>
        <div v-else class="text-center">
          Coming soon
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
  import nodes from "./nodes.json";
  import CopyToClipboard from "../Feeds/components/CopyToClipboard.vue";
  import truncateString from "@/core/truncate";
  import { RouteParamsHandler } from "@/core/RouteParamsHandler";
  import { mapState } from "vuex";

  export default {
    name: "NodesTable",
    components: {
      CopyToClipboard,
    },
    data() {
      return {
        nodes,
        routeParamsHandler: null,
        fields: [
          {
            key: "name",
            label: "Data provider",
            stickyColumn: true,
            sortable: true,
            thStyle: { width: "300px" },
          },
          {
            key: "evmAddress",
            label: "EVM Address",
            sortable: false,
            tdClass: "w-1/3",
          },
        ],
      };
    },
    computed: {
      ...mapState("layout", ["searchTerm"]),
      filteredNodes() {
        if (!this.searchTerm) return this.nodes;

        const searchLower = this.searchTerm.toLowerCase();
        return this.nodes.filter(
          (node) =>
            node.name.toLowerCase().includes(searchLower) ||
            node.dataServiceId.toLowerCase().includes(searchLower)
        );
      },
    },
    methods: {
      truncateString,
      async initializeFromRoute() {
        const routeParams = this.$route.query;
        if (routeParams.search) {
          await this.$store.dispatch(
            "layout/updateSearchTerm",
            routeParams.search
          );
        }
      },
    },
    async mounted() {
      this.routeParamsHandler = new RouteParamsHandler(
        this.$router,
        {
          pageParam: "page",
          searchParam: "search",
        },
        16
      );
      await this.initializeFromRoute();
      this.$nextTick(() => {
        this.routeParamsHandler.setInitialLoadComplete();
      });
    },
  };
</script>

<style lang="scss">
  #data-providers-table {
    .feeds__token-image {
      width: 60px;
      height: 60px;
    }
  }
</style>

<template>
  <div class="dashboard">
    <img class="logo-image" src="/redstone-logo-white.svg" />
    <div v-if="loading" class="loading">Loading dashboard data...</div>
    <div v-if="error" class="error-message">{{ error }}</div>

    <div v-if="!loading && !error" class="dashboard__content">
      <!-- <TimeframeSelector
        :timeframes="timeframes"
        :selected-timeframe="selectedTimeframe"
        @update:timeframe="selectedTimeframe = $event"
      /> -->

      <KeyMetricsGrid
        :tvs-sum="tvsSum"
        :tvs-daily-change="tvsDailyChange"
        :active-providers="activeProviders"
        :daily-transactions="dailyTransactions"
        :daily-volume="dailyVolume"
        :tvs-per-client="tvsPerClient"
        :avg-response-time="avgResponseTime"
        :clients-tvl="clientsTvl"
      />

      <TvsTimelineChart
        :historical-tvs-per-client="filteredHistoricalTvsPerClient"
        :chart-theme="chartTheme"
      />

      <TvsTimelineChartClient
        :historical-data="filteredHistoricalTvsPerClient"
        :chart-theme="chartTheme"
        :color-palette="colorPalette"
      />

      <!-- <div class="charts-row">
        <ProviderPerformanceChart
          :chart-theme="chartTheme"
          :color-palette="colorPalette"
        />
        <PriceUpdatesChart
          :chart-theme="chartTheme"
          :color-palette="colorPalette"
        />
      </div> -->
      <!-- 
      <ClientDistributionSummary
        :tvs-sum="tvsSum"
        :tvs-per-client="tvsPerClient"
      /> -->

      <ClientTreemap
        :tvs-per-client="tvsPerClient"
        :chart-theme="chartTheme"
        :color-palette="colorPalette"
      />

      <!-- <TopClientsChart
        :tvs-per-client="tvsPerClient"
        :chart-theme="chartTheme"
        :color-palette="colorPalette"
      /> -->

      <ClientList
        :tvs-per-client="tvsPerClient"
        :color-palette="colorPalette"
      />
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import TimeframeSelector from "./TimeframeSelector.vue";
  import KeyMetricsGrid from "./KeyMetricsGrid.vue";
  import TvsTimelineChart from "./TvsTimelineChart.vue";
  import TvsTimelineChartClient from "./TvsTimelineChartClient.vue";
  import ProviderPerformanceChart from "./ProviderPerformanceChart.vue";
  import PriceUpdatesChart from "./PriceUpdatesChart.vue";
  import ClientDistributionSummary from "./ClientDistributionSummary.vue";
  import ClientTreemap from "./ClientTreemap.vue";
  import TopClientsChart from "./TopClientsChart.vue";
  import ClientList from "./ClientList.vue";

  // import historical from "./historical.json";

  export default {
    name: "TvsDashboard",

    components: {
      TimeframeSelector,
      KeyMetricsGrid,
      TvsTimelineChart,
      ProviderPerformanceChart,
      PriceUpdatesChart,
      ClientDistributionSummary,
      ClientTreemap,
      TvsTimelineChartClient,
      TopClientsChart,
      ClientList,
    },

    data() {
      return {
        tvsSum: 0,
        tvsPerClient: {},
        loading: true,
        error: null,
        timeseriesData: [],
        activeProviders: 0,
        dailyTransactions: 0,
        dailyVolume: 0,
        avgResponseTime: 0,
        tvsDailyChange: 0,
        historicalTvsPerClient: [],
        filteredHistoricalTvsPerClient: [],
        selectedTimeframe: "1M",
        timeframes: ["1M", "3M", "1Y"],
        colorPalette: [
          "#e41939",
          "#ae0822",
          "#e41a39",
          "#ff4b5c",
          "#8b1e2c",
          "#3d0309",
        ],
      };
    },

    computed: {
      filteredTimeseriesData() {
        if (!this.timeseriesData.length) return [];

        const now = Date.now();
        const timeframeMap = {
          "24H": 24 * 60 * 60 * 1000,
          "1W": 7 * 24 * 60 * 60 * 1000,
          "1M": 30 * 24 * 60 * 60 * 1000,
          "3M": 90 * 24 * 60 * 60 * 1000,
          "1Y": 365 * 24 * 60 * 60 * 1000,
          ALL: Infinity,
        };

        const timeframe = timeframeMap[this.selectedTimeframe];
        const cutoffTime = now - timeframe;

        return this.timeseriesData.filter((point) => point.x >= cutoffTime);
      },
      chartTheme() {
        return {
          mode: "dark",
          monochrome: {
            enabled: true,
            color: "#e41939",
            shadeTo: "dark",
            shadeIntensity: 0.65,
          },
        };
      },
    },

    methods: {
      formatNumber(num) {
        return new Intl.NumberFormat("en-US", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }).format(num);
      },

      // generateTimeseriesData() {
      //   if (!this.historicalData?.historicalValues?.historicalTvsSum?.length) {
      //     return [];
      //   }

      //   return this.historicalData.historicalValues.historicalTvsSum.map(
      //     (dataPoint) => ({
      //       x: dataPoint.date * 1000, // Convert UNIX timestamp to milliseconds
      //       y: Math.round(dataPoint.totalLiquidityUSD),
      //     })
      //   );
      // },
      filterHistoricalData(historicalData, currentClients) {
        // Normalize client names for comparison
        function normalizeClientName(name) {
          return name
            .toLowerCase()
            .replace(/\s/g, "")
            .replace(/-/g, "")
            .replace(/\./g, "");
        }

        // Create a mapping of normalized current client names
        const normalizedCurrentClients = {};
        Object.keys(currentClients).forEach((key) => {
          normalizedCurrentClients[normalizeClientName(key)] = key;
        });

        const result = {};

        // Process each timestamp in the historical data
        Object.keys(historicalData).forEach((timestamp) => {
          result[timestamp] = {};

          // Filter clients for this timestamp
          Object.keys(historicalData[timestamp]).forEach((historicalClient) => {
            const normalizedName = normalizeClientName(historicalClient);

            // Check if this historical client exists in current clients (after normalization)
            if (normalizedCurrentClients[normalizedName]) {
              // Use the current client name (from current endpoint) as the key
              // This ensures consistent naming between historical and current data
              const currentClientName =
                normalizedCurrentClients[normalizedName];
              result[timestamp][currentClientName] =
                historicalData[timestamp][historicalClient];
            }
          });
        });

        return result;
      },

      async fetchData() {
        try {
          const [sumResponse, clientsResponse, clientsTvl, historicalClient] =
            await Promise.all([
              axios.get("https://client-tvs.a.redstone.finance/tvs-sum"),
              axios.get("https://client-tvs.a.redstone.finance/tvs-per-client"),
              axios.get("https://client-tvs.a.redstone.finance/tvl-per-client"),
              axios.get("https://client-tvs.a.redstone.finance/historical-tvs"),
            ]);

          this.clientsTvl = clientsTvl.data;
          this.tvsSum = sumResponse.data;
          this.tvsPerClient = clientsResponse.data;
          this.historicalTvsPerClient = historicalClient.data;

          // Filter historical data to only include clients from tvsPerClient
          this.filteredHistoricalTvsPerClient = this.filterHistoricalData(
            this.historicalTvsPerClient,
            this.tvsPerClient
          );

          this.activeProviders = 15;
          this.dailyTransactions = 125000;
          this.dailyVolume = this.tvsSum * 0.15;
          this.avgResponseTime = 0.15;
          this.tvsDailyChange = 2.5;
        } catch (err) {
          this.error = "Failed to load dashboard data: " + err.message;
        } finally {
          this.loading = false;
        }
      },
    },

    mounted() {
      this.fetchData();
    },
  };
</script>

<style lang="scss" src="./TvsDashboard.scss"></style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "token-wrapper"
  }, [_vm.isDisclaimerRequired(_vm.$route.path) ? _c('disclaimer-modal', {
    on: {
      "disclaimer-accepted": _vm.onDisclaimerAccepted,
      "disclaimer-declined": _vm.onDisclaimerDeclined
    }
  }) : _vm._e(), _vm.showContent ? _c('div', {
    staticClass: "token"
  }, [_c('div', {
    staticClass: "select-provider-wrapper d-flex justify-content-between mt-4 mt-md-0"
  }, [_vm.providers.length > 1 ? _c('div', {
    staticClass: "select-provider"
  }, [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Data provider:",
      "label-for": "select-provider",
      "label-cols": "6",
      "content-cols": "6"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.providers,
      "id": "select-provider"
    },
    model: {
      value: _vm.selectedProvider,
      callback: function callback($$v) {
        _vm.selectedProvider = $$v;
      },
      expression: "selectedProvider"
    }
  })], 1)], 1)], 1) : _vm._e()]), _c('div', {
    staticClass: "token-data-wrapper"
  }, [_c('TokenPriceChartContainer', {
    key: _vm.symbol + _vm.selectedProvider + '-chart',
    attrs: {
      "symbol": _vm.symbol,
      "provider": _vm.selectedProvider,
      "currentPrice": _vm.currentPrice
    }
  }), _c('TokenPriceTableContainer', {
    key: _vm.symbol + _vm.selectedProvider + '-table',
    attrs: {
      "id": "token-price-table",
      "symbol": _vm.symbol,
      "provider": _vm.selectedProvider,
      "currentPrice": _vm.currentPrice
    }
  })], 1), _c('div', {
    staticClass: "space"
  })]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
// src/components/ClientDistributionSummary.vue
<template>
  <div class="summary-section">
    <div class="summary-card">
      <div class="summary-card__content">
        <h2 class="summary-card__title">Total TVS</h2>
        <p class="summary-card__value">${{ formatNumber(tvsSum) }}</p>
      </div>
      <div class="summary-card__content">
        <h2 class="summary-card__title">Projects used to calculate TVS</h2>
        <p class="summary-card__value">{{ Object.keys(tvsPerClient).length }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ClientDistributionSummary',
  props: {
    tvsSum: {
      type: Number,
      required: true
    },
    tvsPerClient: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(num);
    }
  }
};
</script>
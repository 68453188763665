var render = function render(){
  var _vm$feedData, _vm$feedData$apiValue, _vm$feedData2, _vm$feedData3, _vm$feedData3$apiValu, _vm$feedData4, _vm$denominationMap$_, _vm$feedData5, _vm$feedData6, _vm$feedData7, _vm$feedData7$apiValu, _vm$feedData8, _vm$denominationMap$_2, _vm$feedData9, _vm$denominationMap$_3, _vm$feedData10, _vm$feedData10$apiVal, _vm$feedData11, _vm$feedData12, _vm$feedData12$apiVal, _vm$feedData13, _vm$feedData14, _vm$feedData15, _vm$feedData16, _vm$feedData16$explor, _vm$feedData17, _vm$feedData17$networ, _vm$feedData18, _vm$feedData19, _vm$feedData20, _vm$feedData20$loader, _vm$feedData21, _vm$feedData21$apiVal, _vm$feedData22, _vm$denominationMap$_4, _vm$feedData23, _vm$feedData24, _vm$feedData25, _vm$feedData26, _vm$feedData34, _vm$denominationMap$_8, _vm$feedData35, _vm$denominationMap$_9, _vm$feedData36, _vm$feedData37, _vm$feedData38, _vm$feedData38$apiVal, _vm$feedData39;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.isDisclaimerRequired(_vm.$route.path) ? _c('disclaimer-modal', {
    on: {
      "disclaimer-accepted": _vm.onDisclaimerAccepted,
      "disclaimer-declined": _vm.onDisclaimerDeclined
    }
  }) : _vm._e(), _vm.showContent ? _c('div', {
    staticClass: "feed-details"
  }, [_c('dl', {
    staticClass: "stats-grid"
  }, [_c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Answer")]), (_vm$feedData = _vm.feedData) !== null && _vm$feedData !== void 0 && (_vm$feedData$apiValue = _vm$feedData.apiValues) !== null && _vm$feedData$apiValue !== void 0 && _vm$feedData$apiValue.value || (_vm$feedData2 = _vm.feedData) !== null && _vm$feedData2 !== void 0 && _vm$feedData2.answer ? _c('dd', {
    staticClass: "stat-value"
  }, [!_vm.sUSDe_RATE ? _c('strong', {
    domProps: {
      "innerHTML": _vm._s(_vm.parseToCurrency(((_vm$feedData3 = _vm.feedData) === null || _vm$feedData3 === void 0 ? void 0 : (_vm$feedData3$apiValu = _vm$feedData3.apiValues) === null || _vm$feedData3$apiValu === void 0 ? void 0 : _vm$feedData3$apiValu.value) || ((_vm$feedData4 = _vm.feedData) === null || _vm$feedData4 === void 0 ? void 0 : _vm$feedData4.answer), (_vm$denominationMap$_ = _vm.denominationMap[(_vm$feedData5 = _vm.feedData) === null || _vm$feedData5 === void 0 ? void 0 : _vm$feedData5.token]) === null || _vm$denominationMap$_ === void 0 ? void 0 : _vm$denominationMap$_.denomination, (_vm$feedData6 = _vm.feedData) === null || _vm$feedData6 === void 0 ? void 0 : _vm$feedData6.token, ((_vm$feedData7 = _vm.feedData) === null || _vm$feedData7 === void 0 ? void 0 : (_vm$feedData7$apiValu = _vm$feedData7.apiValues) === null || _vm$feedData7$apiValu === void 0 ? void 0 : _vm$feedData7$apiValu.value) == null && ((_vm$feedData8 = _vm.feedData) === null || _vm$feedData8 === void 0 ? void 0 : _vm$feedData8.answer) != null))
    }
  }) : _c('strong', [_vm._v(_vm._s(_vm.currencySymbolMap[(_vm$denominationMap$_2 = _vm.denominationMap[(_vm$feedData9 = _vm.feedData) === null || _vm$feedData9 === void 0 ? void 0 : _vm$feedData9.token]) === null || _vm$denominationMap$_2 === void 0 ? void 0 : _vm$denominationMap$_2.denomination] || ((_vm$denominationMap$_3 = _vm.denominationMap[_vm.feedData.token]) === null || _vm$denominationMap$_3 === void 0 ? void 0 : _vm$denominationMap$_3.denomination)) + " "), _c('span', {
    domProps: {
      "innerHTML": _vm._s(_vm.formatPriceWithoutCurrency(((_vm$feedData10 = _vm.feedData) === null || _vm$feedData10 === void 0 ? void 0 : (_vm$feedData10$apiVal = _vm$feedData10.apiValues) === null || _vm$feedData10$apiVal === void 0 ? void 0 : _vm$feedData10$apiVal.value) || ((_vm$feedData11 = _vm.feedData) === null || _vm$feedData11 === void 0 ? void 0 : _vm$feedData11.answer), _vm.sUSDe_RATE, ((_vm$feedData12 = _vm.feedData) === null || _vm$feedData12 === void 0 ? void 0 : (_vm$feedData12$apiVal = _vm$feedData12.apiValues) === null || _vm$feedData12$apiVal === void 0 ? void 0 : _vm$feedData12$apiVal.value) == null && ((_vm$feedData13 = _vm.feedData) === null || _vm$feedData13 === void 0 ? void 0 : _vm$feedData13.answer) != null))
    }
  })]), _c('span', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "timestamp",
    attrs: {
      "title": _vm.feedData.updateTime
    }
  }, [_vm._v("Updated " + _vm._s(_vm.feedData.humanUpdateTime))])]) : _c('Loader')], 1), _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Network")]), _c('dd', {
    staticClass: "stat-value-wrapper"
  }, [_c('div', {
    staticClass: "stat-value"
  }, [_vm.feedData ? _c('img', {
    staticClass: "feeds__token-image small",
    attrs: {
      "src": (_vm$feedData14 = _vm.feedData) === null || _vm$feedData14 === void 0 ? void 0 : _vm$feedData14.network.image,
      "alt": (_vm$feedData15 = _vm.feedData) === null || _vm$feedData15 === void 0 ? void 0 : _vm$feedData15.network.name
    }
  }) : _vm._e(), _c('span', {
    staticClass: "applicant-info__text"
  }, [_c('a', {
    attrs: {
      "href": (_vm$feedData16 = _vm.feedData) === null || _vm$feedData16 === void 0 ? void 0 : (_vm$feedData16$explor = _vm$feedData16.explorer) === null || _vm$feedData16$explor === void 0 ? void 0 : _vm$feedData16$explor.explorerUrl,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s((_vm$feedData17 = _vm.feedData) === null || _vm$feedData17 === void 0 ? void 0 : (_vm$feedData17$networ = _vm$feedData17.network) === null || _vm$feedData17$networ === void 0 ? void 0 : _vm$feedData17$networ.name) + " ")])])])])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Symbol")]), _c('dd', {
    staticClass: "stat-value"
  }, [_vm._v(_vm._s((_vm$feedData18 = _vm.feedData) === null || _vm$feedData18 === void 0 ? void 0 : _vm$feedData18.token))])]), _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Deviation threshold")]), _c('dd', {
    staticClass: "stat-value"
  }, [_vm._v(_vm._s((_vm$feedData19 = _vm.feedData) === null || _vm$feedData19 === void 0 ? void 0 : _vm$feedData19.deviation))])]), _vm.feedData ? _c('div', {
    staticClass: "stat-item"
  }, [_c('dt', {
    staticClass: "stat-title"
  }, [_vm._v("Heartbeat")]), _c('dd', {
    staticClass: "stat-value"
  }, [(_vm$feedData20 = _vm.feedData) !== null && _vm$feedData20 !== void 0 && (_vm$feedData20$loader = _vm$feedData20.loaders) !== null && _vm$feedData20$loader !== void 0 && _vm$feedData20$loader.blockTimestamp && ((_vm$feedData21 = _vm.feedData) === null || _vm$feedData21 === void 0 ? void 0 : (_vm$feedData21$apiVal = _vm$feedData21.apiValues) === null || _vm$feedData21$apiVal === void 0 ? void 0 : _vm$feedData21$apiVal.timestamp) == null ? _c('Loader', {
    staticClass: "feeds__loader"
  }) : _c('span', {
    staticClass: "feeds__timestamp"
  }, [_vm.heartbeatIsNumber(_vm.feedData.heartbeat) ? _c('span', [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.feedData.heartbeatTitle))]), _c('to-date-counter', {
    staticClass: "ml-2",
    attrs: {
      "interval": _vm.feedData.heartbeatInterval,
      "duration": _vm.feedData.heartbeat
    }
  })], 1)]) : _c('div', [_c('span', {
    staticClass: "cron-trigger",
    attrs: {
      "id": "cron-trigger-".concat(_vm.feedData.layer_id)
    }
  }, [_c('span', [_vm._v("Cron: " + _vm._s(_vm.feedData.heartbeatTitle))]), _c('cron-counter', {
    staticClass: "ml-2",
    attrs: {
      "crons": _vm.feedData.heartbeat
    }
  })], 1)])])], 1)]) : _vm._e()]), _vm.feedData ? _c('contract-address', {
    staticClass: "contract-address",
    attrs: {
      "item": _vm.feedData,
      "disable-truncate": "",
      "separate-labels": false
    }
  }) : _vm._e(), _c('div', {
    staticClass: "chart-container"
  }, [_c('div', {
    staticClass: "feed-chart"
  }, [_vm.currentChartData && !_vm.isLoading ? _c('FeedChart', {
    ref: "feedChart",
    attrs: {
      "explorer": (_vm$feedData22 = _vm.feedData) === null || _vm$feedData22 === void 0 ? void 0 : _vm$feedData22.explorer,
      "data": _vm.currentChartData,
      "range": _vm.currentRange,
      "denomination": (_vm$denominationMap$_4 = _vm.denominationMap[(_vm$feedData23 = _vm.feedData) === null || _vm$feedData23 === void 0 ? void 0 : _vm$feedData23.token]) === null || _vm$denominationMap$_4 === void 0 ? void 0 : _vm$denominationMap$_4.denomination,
      "special-denomination": _vm.sUSDe_RATE,
      "duplicated-ranges": _vm.duplicateRanges.flat(),
      "currency": _vm.getCurrency(_vm.feedData.token),
      "maxDataPoints": 500
    },
    on: {
      "range-change": _vm.handleRangeChange,
      "displayed-data-change": _vm.onDisplayedDataChange
    }
  }) : _c('div', {
    staticClass: "loading-container"
  }, [_c('vue-loaders-ball-beat', {
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "1"
    }
  })], 1)], 1)])], 1) : _vm._e(), _c('div', {
    staticClass: "feed-details sources mt-4"
  }, [_c('div', {
    staticClass: "sources-title"
  }, [_vm._v(" Sources "), _c('RouterLink', {
    attrs: {
      "to": {
        name: 'TokenPage',
        params: {
          symbol: (_vm$feedData24 = _vm.feedData) !== null && _vm$feedData24 !== void 0 && _vm$feedData24.token.includes('/') ? (_vm$feedData25 = _vm.feedData) === null || _vm$feedData25 === void 0 ? void 0 : _vm$feedData25.token.replace('/', '-') : (_vm$feedData26 = _vm.feedData) === null || _vm$feedData26 === void 0 ? void 0 : _vm$feedData26.token
        }
      }
    }
  }, [_c('span', {
    staticClass: "see-more-button"
  }, [_vm._v("See more")])])], 1), _vm.displayedSources ? _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.pullPrices != null,
      expression: "pullPrices != null"
    }],
    staticClass: "sources-images"
  }, _vm._l(_vm.displayedSources, function (source) {
    var _vm$pullPrices, _vm$denominationMap$_5, _vm$feedData27, _vm$feedData28, _vm$feedData29, _vm$feedData29$apiVal, _vm$feedData30, _vm$denominationMap$_6, _vm$feedData31, _vm$denominationMap$_7, _vm$feedData32, _vm$feedData32$apiVal, _vm$feedData33;
    return ((_vm$pullPrices = _vm.pullPrices) === null || _vm$pullPrices === void 0 ? void 0 : _vm$pullPrices[source.name]) != null ? _c('div', {
      key: source === null || source === void 0 ? void 0 : source.key,
      staticClass: "single-source"
    }, [_c('img', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "src": source === null || source === void 0 ? void 0 : source.imageUrl,
        "title": source === null || source === void 0 ? void 0 : source.name,
        "alt": source === null || source === void 0 ? void 0 : source.name
      }
    }), _c('div', {
      staticClass: "source-price"
    }, [!_vm.sUSDe_RATE ? _c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.parseToCurrency(_vm.pullPrices[source === null || source === void 0 ? void 0 : source.name], (_vm$denominationMap$_5 = _vm.denominationMap[(_vm$feedData27 = _vm.feedData) === null || _vm$feedData27 === void 0 ? void 0 : _vm$feedData27.token]) === null || _vm$denominationMap$_5 === void 0 ? void 0 : _vm$denominationMap$_5.denomination, (_vm$feedData28 = _vm.feedData) === null || _vm$feedData28 === void 0 ? void 0 : _vm$feedData28.token, ((_vm$feedData29 = _vm.feedData) === null || _vm$feedData29 === void 0 ? void 0 : (_vm$feedData29$apiVal = _vm$feedData29.apiValues) === null || _vm$feedData29$apiVal === void 0 ? void 0 : _vm$feedData29$apiVal.value) == null && ((_vm$feedData30 = _vm.feedData) === null || _vm$feedData30 === void 0 ? void 0 : _vm$feedData30.answer) != null))
      }
    }) : _c('span', [_vm._v(_vm._s(_vm.currencySymbolMap[(_vm$denominationMap$_6 = _vm.denominationMap[(_vm$feedData31 = _vm.feedData) === null || _vm$feedData31 === void 0 ? void 0 : _vm$feedData31.token]) === null || _vm$denominationMap$_6 === void 0 ? void 0 : _vm$denominationMap$_6.denomination] || ((_vm$denominationMap$_7 = _vm.denominationMap[_vm.feedData.token]) === null || _vm$denominationMap$_7 === void 0 ? void 0 : _vm$denominationMap$_7.denomination)) + " "), _c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.formatPriceWithoutCurrency(_vm.pullPrices[source.name], _vm.sUSDe_RATE, ((_vm$feedData32 = _vm.feedData) === null || _vm$feedData32 === void 0 ? void 0 : (_vm$feedData32$apiVal = _vm$feedData32.apiValues) === null || _vm$feedData32$apiVal === void 0 ? void 0 : _vm$feedData32$apiVal.value) == null && ((_vm$feedData33 = _vm.feedData) === null || _vm$feedData33 === void 0 ? void 0 : _vm$feedData33.answer) != null))
      }
    })])])]) : _vm._e();
  }), 0) : _vm._e(), _vm.pullPrices == null ? _c('vue-loaders-ball-beat', {
    attrs: {
      "color": "var(--redstone-red-color)",
      "scale": "0.4"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "feed-details mt-4"
  }, [_c('TransactionsTable', {
    attrs: {
      "explorer": (_vm$feedData34 = _vm.feedData) === null || _vm$feedData34 === void 0 ? void 0 : _vm$feedData34.explorer,
      "data": _vm.displayedData.length > 0 ? _vm.displayedData : _vm.currentChartData,
      "special-denomination": _vm.sUSDe_RATE,
      "denomination": _vm.currencySymbolMap[(_vm$denominationMap$_8 = _vm.denominationMap[(_vm$feedData35 = _vm.feedData) === null || _vm$feedData35 === void 0 ? void 0 : _vm$feedData35.token]) === null || _vm$denominationMap$_8 === void 0 ? void 0 : _vm$denominationMap$_8.denomination] || ((_vm$denominationMap$_9 = _vm.denominationMap[(_vm$feedData36 = _vm.feedData) === null || _vm$feedData36 === void 0 ? void 0 : _vm$feedData36.token]) === null || _vm$denominationMap$_9 === void 0 ? void 0 : _vm$denominationMap$_9.denomination),
      "token": (_vm$feedData37 = _vm.feedData) === null || _vm$feedData37 === void 0 ? void 0 : _vm$feedData37.token,
      "big-dec": ((_vm$feedData38 = _vm.feedData) === null || _vm$feedData38 === void 0 ? void 0 : (_vm$feedData38$apiVal = _vm$feedData38.apiValues) === null || _vm$feedData38$apiVal === void 0 ? void 0 : _vm$feedData38$apiVal.value) == null && ((_vm$feedData39 = _vm.feedData) === null || _vm$feedData39 === void 0 ? void 0 : _vm$feedData39.answer) != null
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div class="price-table">
    <div class="table-title">On chain transactions</div>
    <vue-loaders-ball-beat
      color="var(--redstone-red-color)"
      class="mx-auto"
      scale="0.6"
      v-if="data == null"
    ></vue-loaders-ball-beat>
    <div v-else>
      <b-table
        id="prices-table"
        sort-by="time"
        :sort-desc="true"
        class="pull-model"
        stacked="md"
        hover
        :items="visibleTransactions"
        :fields="fields"
      >
        <template #table-busy>
          <vue-loaders-ball-beat
            color="var(--redstone-red-color)"
            scale="0.8"
          ></vue-loaders-ball-beat>
        </template>

        <template #cell(value)="{ item }">
          <div class="price">
            <strong
              v-if="!specialDenomination"
              v-html="parseToCurrency(item.value, denomination, token, bigDec)"
            ></strong>
            <strong v-else
              >{{ denomination }}
              <span
                v-html="
                  formatPriceWithoutCurrency(
                    item.value,
                    specialDenomination,
                    bigDec
                  )
                "
              ></span>
            </strong>
          </div>
        </template>

        <template #cell(time)="{item}">
          <div class="time">
            {{ item.time }}
          </div>
        </template>
        <template #cell(transactionHash)="{item}">
          <a target="_blank" :href="item.url" class="txHash">
            {{`${item.txHash.slice(0, 10)}...${item.txHash.slice(-10)}`}}
          </a>
        </template>
      </b-table>
    </div>
    <div 
      v-if="hasMoreItems" 
      class="text-center py-3 loading-indicator"
      ref="loadingIndicator"
    >
      <vue-loaders-ball-beat
        color="var(--redstone-red-color)"
        scale="0.7"
      ></vue-loaders-ball-beat>
    </div>
    
    <div v-if="!hasMoreItems && visibleTransactions.length > 0" class="text-center py-3 end-message">
      No more transactions to load
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import {
  parseToCurrency,
  formatPriceWithoutCurrency,
} from "../utils/FeedsTableDataLayer";

export default {
  name: "TransactionsTable",

  props: {
    data: {
      type: Array,
      default: () => []
    },
    specialDenomination: {
      type: Boolean,
      default: false
    },
    explorer: {
      required: true
    },
    denomination: {
      type: String,
      default: ""
    },
    token: {
      type: String,
      default: ""
    },
    bigDec: {
      type: Boolean,
      default: false
    }
  },
  
  data() {
    return {
      fields: ["value", "time", "transactionHash"],
      isLoading: false,
      currentIndex: 0,
      observer: null,
      visibleTransactions: [],
      initialItemCount: 10,
      itemsPerBatch: 10
    };
  },
  
  computed: {
    allTransactions() {
      if (!this.data) return [];
      
      return this.data.map((transaction) => ({
        time: format(transaction.timestamp, "MMMM d, yyyy h:mm a zzz"),
        value: transaction.value,
        txHash: transaction.txHash,
        timestamp: transaction.timestamp,
        url: `${this.explorer.explorerUrl}/${this.explorer.txSlug}/${transaction.txHash}?${this.explorer.getParameters}`,
      })).sort((a, b) => b.timestamp - a.timestamp);
    },
    hasMoreItems() {
      return this.currentIndex < this.allTransactions.length;
    }
  },
  
  methods: {
    parseToCurrency,
    formatPriceWithoutCurrency,
    
    loadMoreItems() {
      if (this.isLoading || !this.hasMoreItems) return;
      
      this.isLoading = true;
      
      // Use setTimeout to prevent UI blocking
      setTimeout(() => {
        const startIndex = this.currentIndex;
        const endIndex = Math.min(
          startIndex + this.itemsPerBatch,
          this.allTransactions.length
        );
        
        const newItems = this.allTransactions.slice(startIndex, endIndex);
        this.visibleTransactions = [...this.visibleTransactions, ...newItems];
        this.currentIndex = endIndex;
        this.isLoading = false;
      }, 300);
    },
    
    setupIntersectionObserver() {
      // Create intersection observer only after the component is mounted
      this.$nextTick(() => {
        if (!this.observer && this.$refs.loadingIndicator) {
          this.observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && !this.isLoading) {
              this.loadMoreItems();
            }
          }, {
            root: null,
            rootMargin: '100px',
            threshold: 0.1
          });
          
          this.observer.observe(this.$refs.loadingIndicator);
        }
      });
    },
    
    // Initialize data when component is first loaded
    initializeData() {
      this.visibleTransactions = [];
      this.currentIndex = 0;
      
      // Load initial batch (10 items)
      if (this.allTransactions.length > 0) {
        const initialItems = this.allTransactions.slice(0, this.initialItemCount);
        this.visibleTransactions = initialItems;
        this.currentIndex = initialItems.length;
      }
      
      // Setup the observer after data is initialized
      this.setupIntersectionObserver();
    }
  },
  
  watch: {
    data: {
      handler() {
        this.initializeData();
      },
      immediate: true
    }
  },
  
  mounted() {
    this.setupIntersectionObserver();
  },
  
  beforeDestroy() {
    // Clean up observer on component destruction
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
  }
};
</script>

<style lang="scss" scoped>
.table-title {
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333333;
}

.price-table {
  width: 100%;
  margin-top: 0px;
  padding: 0 30px;
}

.tx-link {
  font-size: 12px;
}

a.tx-link,
.tx-link > .link {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

a.tx-link {
  color: var(--redstone-red-color);
}

#prices-table {
  box-shadow: none;
  td {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center !important;
  }

  th {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.loading-indicator {
  margin-top: 10px;
  margin-bottom: 10px;
}

.end-message {
  color: #888;
  font-size: 14px;
  margin-top: 10px;
}
</style>
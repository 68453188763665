<template>
  <div class="chart-card">
    <h3>TVS Over Time</h3>
    <apexchart
      type="area"
      height="350"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
  import VueApexCharts from "vue-apexcharts";
  import _ from "lodash";

  export default {
    name: "TvsTimelineChart",
    components: {
      apexchart: VueApexCharts,
    },
    props: {
      historicalTvsPerClient: {
        type: Object,
        required: true,
      },
      chartTheme: {
        type: Object,
        required: true,
      },
      dropThreshold: {
        type: Number,
        default: 0.2, // 20% drop threshold
      },
    },
    computed: {
      series() {
        const timeseriesData = Object.entries(this.historicalTvsPerClient)
          .map(([timestamp, clients]) => {
            const totalTVS =
              Object.values(clients).reduce((sum, value) => {
                const parsed = parseFloat(value);
                return sum + (isNaN(parsed) ? 0 : parsed);
              }, 0);

            return {
              x: parseInt(timestamp) * 1000,
              y: totalTVS || null,
            };
          })
          .sort((a, b) => a.x - b.x);

        // Find last valid data point before significant drop
        let lastValidIndex = timeseriesData.length - 1;
        for (let i = 1; i < timeseriesData.length; i++) {
          const drop =
            (timeseriesData[i - 1].y - timeseriesData[i].y) /
            timeseriesData[i - 1].y;
          if (drop > this.dropThreshold) {
            lastValidIndex = i - 1;
            break;
          }
        }

        // Filter data up to last valid point
        const validData = timeseriesData
          .slice(0, lastValidIndex + 1)
          .filter((point) => point.y !== null);

        return [
          {
            name: "Total TVS",
            data: validData,
          },
        ];
      },
      chartOptions() {
        return {
          chart: {
            type: "area",
            height: 350,
            background: "#13111b",
            foreColor: "#fff",
            toolbar: {
              show: false, // Hide toolbar
            },
            zoom: {
              enabled: false, // Disable zoom
            },
            selection: {
              enabled: false, // Disable selection
            },
            animations: {
              enabled: true,
            },
          },
          theme: this.chartTheme,
          stroke: {
            curve: "smooth",
            width: 2,
          },
          fill: {
            type: "gradient",
            gradient: {
              shadeIntensity: 1,
              opacityFrom: 0.7,
              opacityTo: 0.3,
              stops: [0, 90, 100],
              colorStops: [
                {
                  offset: 0,
                  color: "#e41939",
                  opacity: 0.6,
                },
                {
                  offset: 100,
                  color: "#3d0309",
                  opacity: 0.2,
                },
              ],
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              style: {
                colors: "#fff",
              },
              datetimeFormatter: {
                year: "yyyy",
                month: "MMM 'yy",
                day: "dd MMM",
                hour: "HH:mm",
              },
            },
          },
          yaxis: {
            labels: {
              formatter: (value) => this.formatNumber(value),
              style: {
                colors: "#fff",
              },
            },
            forceNiceScale: true,
            tickAmount: 5,
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            enabled: true,
            followCursor: true,
            theme: "dark",
            style: {
              fontSize: "12px",
            },
            x: {
              show: true,
            },
            y: {
              formatter: (value) => `TVS: ${this.formatNumber(value)}`,
            },
            marker: {
              show: false,
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 0,
              sizeOffset: 6,
            },
          },
          grid: {
            borderColor: "rgba(255,255,255,0.1)",
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
            },
          },
        };
      },
    },
    methods: {
      formatNumber(value) {
        if (value >= 1e9) {
          return `$${(value / 1e9).toFixed(2)}B`;
        } else if (value >= 1e6) {
          return `$${(value / 1e6).toFixed(2)}M`;
        } else if (value >= 1e3) {
          return `$${(value / 1e3).toFixed(2)}K`;
        }
        return `$${value.toFixed(2)}`;
      },
    },
  };
</script>

<template>
  <div class="timeframe-selector">
    <button
      v-for="tf in timeframes"
      :key="tf"
      :class="['timeframe-btn', { active: selectedTimeframe === tf }]"
      @click="$emit('update:timeframe', tf)"
    >
      {{ tf }}
    </button>
  </div>
</template>

<script>
  export default {
    name: "TimeframeSelector",
    props: {
      timeframes: {
        type: Array,
        required: true,
      },
      selectedTimeframe: {
        type: String,
        required: true,
      },
    },
  };
</script>

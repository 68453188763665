<template>
  <div class="chart-card">
    <h3>TVS per client</h3>
    <div class="charts-container">
      <apexchart
        type="treemap"
        height="500"
        :options="chartOptions"
        :series="series"
      />
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'ClientTreemap',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    tvsPerClient: {
      type: Object,
      required: true
    },
    chartTheme: {
      type: Object,
      required: true
    },
    colorPalette: {
      type: Array,
      required: true
    }
  },
  computed: {
    chartOptions() {
      const sortedClients = Object.entries(this.tvsPerClient)
        .sort(([, a], [, b]) => b - a)
        .slice(0, 10);
      const colors = sortedClients.map((_, index) => this.colorPalette[index]);

      return {
        chart: {
          type: 'treemap',
          background: '#13111b',
          foreColor: '#fff',
          toolbar: {
            show: false
          }
        },
        theme: this.chartTheme,
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false
          }
        },
        colors: colors,
        stroke: {
          width: 1,  // Remove borders
          colors: ['rgba(255,255,255,0.3)']
        },
        dataLabels: {
          enabled: true,
          formatter: function(text, op) {
            return [
              text,
              `${new Intl.NumberFormat('en-US').format(op.value)}`
            ];
          },
          style: {
            fontSize: '12px',
            colors: ['#fff']
          }
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: (value) => `${this.formatNumber(value)}`
          }
        },
        legend: {
          show: false
        }
      };
    },
    series() {
      const sortedData = Object.entries(this.tvsPerClient)
        .sort(([, a], [, b]) => b - a)
        .map(([name, value]) => ({
          x: name,
          y: value
        }));

      return [{
        data: sortedData
      }];
    }
  },
  methods: {
    formatNumber(num) {
      return new Intl.NumberFormat('en-US', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(num);
    }
  }
};
</script>
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "metrics-grid"
  }, [_c('div', {
    staticClass: "metric-card"
  }, [_c('h3', [_vm._v("Total TVS")]), _c('p', {
    staticClass: "metric-value"
  }, [_vm._v("$" + _vm._s(_vm.formatNumber(_vm.tvsSum)))])]), _c('div', {
    staticClass: "metric-card"
  }, [_c('h3', [_vm._v("Number of assets ")]), _c('p', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(_vm.formatNumber(Object.keys(_vm.tokens).length + 300)))])]), _c('div', {
    staticClass: "metric-card"
  }, [_c('h3', [_vm._v("Users")]), _c('p', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(_vm.formatNumber(121)))])]), _c('div', {
    staticClass: "metric-card"
  }, [_c('h3', [_vm._v("Supported networks")]), _c('p', {
    staticClass: "metric-value"
  }, [_vm._v(_vm._s(_vm.formatNumber(Object.keys(_vm.networks).length + 31)))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }
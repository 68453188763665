// src/components/PriceUpdatesChart.vue
<template>
  <div class="chart-card">
    <h3>Price Updates Distribution</h3>
    <apexchart
      type="bar"
      height="200"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'PriceUpdatesChart',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    chartTheme: {
      type: Object,
      required: true
    },
    colorPalette: {
      type: Array,
      required: true
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'bar',
          height: 200,
          background: '#13111b',
          foreColor: '#fff',
          stacked: true,
          toolbar: {
            show: false
          }
        },
        theme: this.chartTheme,
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            borderRadius: 4
          }
        },
        xaxis: {
          categories: ['00:00', '04:00', '08:00', '12:00', '16:00', '20:00'],
          labels: {
            style: {
              colors: '#fff'
            }
          }
        },
        grid: {
          borderColor: 'rgba(255,255,255,0.1)'
        }
      };
    },
    series() {
      return [{
        name: 'Price Updates',
        data: [2100, 1800, 2800, 3200, 2900, 2100]
      }];
    }
  }
};
</script>
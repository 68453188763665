<template>
  <div class="wrapper">
    <div class="chart-header">
      <span>Transactions chart</span>
      <div v-if="data.length === 0" class="no-data">
        <h5>We are collecting the data</h5>
        <span>Chart will be available shortly</span>
      </div>
      <div class="chart-controls">
        <button
          v-for="range in availableRanges"
          :key="range.value"
          class="range-button"
          :class="{ active: selectedRange === range.value }"
          @click="onRangeChange(range.value)"
          v-if="!duplicatedRanges.includes(range.value)"
        >
          {{ range.label }}
        </button>
      </div>
    </div>

    <div class="chart-container">
      <apexchart
        ref="chart"
        type="area"
        :options="chartOptions"
        :series="series"
        :height="isScreen('sm') ? 250 : 450"
      />
    </div>
  </div>
</template>

<script>
  import { processDataForRange, parseTimestamp } from "./dataProcessor";
  import config from "./chartConfig";
  import isScreen from "@/core/screenHelper";
  import { currencySymbolMap } from "../../utils/FeedsTableDataLayer";

  export default {
    name: "FeedChart",
    props: {
      explorer: {
        required: true,
      },
      currency: {
        type: String,
        required: true,
      },
      data: {
        type: Array,
        required: true,
      },
      range: {
        type: String,
        required: true,
      },
      duplicatedRanges: {
        type: Array,
        required: true,
      },
      denomination: {
        type: String,
        required: true,
      },
      specialDenomination: {
        type: Boolean,
        default: false,
      },
      maxDataPoints: {
        type: Number,
        default: 250,
      },
    },

    data() {
      return {
        selectedRange: this.range,
        ranges: [
          { value: "1d", label: "1D" },
          { value: "1w", label: "1W" },
          { value: "1m", label: "1M" },
        ],
        processedData: [],
        originalData: [],
        hoveredPointIndex: null,
        updateCount: 0,
      };
    },

    computed: {
      availableRanges() {
        return this.ranges;
      },

      formattedCurrency() {
        return currencySymbolMap[this.denomination] || this.denomination;
      },

      series() {
        return [
          {
            name: "Price",
            data: this.processedData,
          },
        ];
      },
      chartOptions() {
        const baseConfig = config(this);
        return {
          ...baseConfig,
          chart: {
            ...baseConfig.chart,
            events: {
              ...baseConfig.chart.events,
            },
            animations: {
              enabled: true,
              dynamicAnimation: { enabled: true },
              animateGradually: { enabled: true },
            },
            toolbar: { show: false },
            zoom: { enabled: false },
            selection: { enabled: false },
          },
        };
      },
    },

    methods: {
      isScreen,
      getTimeFormat() {
        switch (this.selectedRange) {
          case "1d":
            return "HH:mm";
          case "1w":
            return "MMM dd HH:mm";
          case "1m":
            return "MMM dd";
          default:
            return "MMM dd";
        }
      },

      processChartData(data = this.data) {
        const sortedData = processDataForRange(data);
        const points = sortedData.map((point) => ({
          x: parseTimestamp(point.timestamp).getTime(),
          y: parseFloat(point.value),
          txHash: point.txHash,
        }));

        // Always store the complete dataset
        this.originalData = [...points];

        // Apply sampling if needed
        if (points.length > this.maxDataPoints) {
          return this.sampleData(points);
        }

        return points;
      },

      sampleData(data) {
        const totalPoints = data.length;
        const step = Math.floor(totalPoints / this.maxDataPoints);

        if (step <= 1) return data;

        const sampledData = [];
        sampledData.push(data[0]);

        for (let i = step; i < totalPoints - step; i += step) {
          sampledData.push(data[i]);
        }

        sampledData.push(data[totalPoints - 1]);
        return sampledData;
      },

      onRangeChange(range) {
        this.selectedRange = range;
        this.$emit("range-change", range);
        this.processedData = this.processChartData();
        this.updateChart();
      },

      updateChart() {
        if (this.$refs.chart) {
          this.$refs.chart.updateOptions(this.chartOptions);
        }
        this.$emit("displayed-data-change", this.processedData);
      },
    },

    watch: {
      data: {
        handler(newData) {
          // Process the original full dataset
          const allProcessedPoints = processDataForRange(newData).map(
            (point) => ({
              x: parseTimestamp(point.timestamp).getTime(),
              y: parseFloat(point.value),
              txHash: point.txHash,
            })
          );

          // Store the original complete dataset
          this.originalData = [...allProcessedPoints];

          // Increment update counter to track data refreshes
          this.updateCount++;

          // Use all data (possibly sampled)
          const sampledData =
            allProcessedPoints.length > this.maxDataPoints
              ? this.sampleData(allProcessedPoints)
              : allProcessedPoints;

          this.processedData = sampledData;

          if (this.$refs.chart) {
            this.$refs.chart.updateSeries(
              [
                {
                  name: "Price",
                  data: sampledData,
                },
              ],
              false
            );
          }

          this.$emit("displayed-data-change", this.processedData);
        },
        immediate: true,
      },
    },

    mounted() {
      this.processedData = this.processChartData();
      this.$emit("displayed-data-change", this.processedData);
    },
  };
</script>

<style lang="scss" scoped>
  .chart-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-bottom: 1px solid #f1f1f1;

    span {
      font-weight: 600;
      color: #333333;
    }
  }

  .chart-controls {
    display: flex;
    gap: 0.5rem;
  }

  .range-button {
    padding: 0.25rem 0.75rem;
    border-radius: 4px;
    border: 1px solid #ae0822;
    background: transparent;
    color: #ae0822;
    font-size: 0.875rem;
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      background: rgba(253, 98, 122, 0.1);
    }

    &.active {
      background: #ae0822;
      color: white;
    }
  }

  .chart-container {
    flex: 1;
    min-height: 0;
    padding: 1rem;
  }

  .no-data {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: rgba($color: #fff, $alpha: 0.1);
    backdrop-filter: blur(10px);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    h4 {
      color: #333333;
    }
    span {
      color: #939393;
      display: block;
    }
  }
</style>

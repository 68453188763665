<template>
  <div v-if="showDisclaimer" class="disclaimer-modal">
    <div class="disclaimer-content">
      <div class="disclaimer-header">
        <div class="disclaimer-title">Disclaimer</div>
      </div>

      <div 
        class="disclaimer-body" 
        ref="disclaimerBody" 
        @scroll="trackScroll"
      >
        <p>
          The Data provided with respect to the BlackRock USD Institutional
          Digital Liquidity Fund (the "Data") are provided "AS IS" and without
          any warranties of any kind, whether express or implied, including but
          not limited to warranties of merchantability, fitness for a particular
          purpose, accuracy, completeness, non-infringement, or error-free
          operation. The Data is made available for informational and
          convenience purposes only and does not constitute the official books
          and records of the fund. In the event of any discrepancies between the
          Data elements and the official books and records, the official books
          and records shall prevail. Neither the Fund nor BlackRock, or any of
          its affiliates, or any third-party contributing information
          (collectively, the "Data Parties") guarantees the timeliness,
          accuracy, or completeness of the Data or its suitability for any
          particular purpose. The Data is for use in facilitating oracle
          transmission services only and shall not be used, in whole or in part,
          as the basis for making any investment decisions, including but not
          limited to decisions about which securities or assets to buy or sell,
          or when to buy or sell them. The Data does not constitute investment,
          legal, or tax advice, and no fiduciary relationship is formed between
          any of the Data Parties and the user of the Data. The user of the Data
          assumes all risks associated with its use. The Data Parties disclaim
          any and all liability arising from or related to the use of the Data,
          including but not limited to any direct, indirect, incidental,
          consequential, punitive, or special damages (including loss of
          profits, trading losses, or damages resulting from reliance on the
          Data) arising out of or in connection with the use or inability to use
          the Data, even if advised of the possibility of such damages.
        </p>
        <p>
          The Data Parties make no guarantees regarding its accuracy,
          completeness, or reliability. None of the Data Parties shall be liable
          for any errors, omissions, or delays in the Data or for any actions
          taken in reliance thereon. The Information may contain data or inputs
          derived from third-party sources. The Information Parties are not
          responsible for the accuracy, completeness, or availability of any
          such third-party data. Any use of third-party data is at the user's
          sole discretion and risk. Nothing in this disclaimer shall be
          construed as excusing or mitigating the user's obligations to comply
          with applicable laws, regulations, or other legal requirements in
          connection with the use of the Data.
        </p>
        <p>
          The foregoing disclaimers and limitations of liability shall not
          exclude or limit liability that cannot be excluded or limited under
          applicable law. In such cases, the liability of the Data Parties shall
          be limited to the maximum extent permitted by applicable law.
        </p>
      </div>

      <div class="disclaimer-actions">
        <button 
          class="btn-agree" 
          @click="acceptDisclaimer"
          :disabled="!hasScrolledToBottom"
        >
          I Agree
        </button>
        <button 
          class="btn-decline" 
          @click="declineDisclaimer"
        >
          Decline
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "DisclaimerModal",

  data() {
    return {
      showDisclaimer: false,
      hasScrolledToBottom: false,
    };
  },

  created() {
    this.checkDisclaimerStatus();
  },

  methods: {
    checkDisclaimerStatus() {
      const currentRoute = this.$route.path;
      const acceptedRoutes = JSON.parse(
        localStorage.getItem("acceptedDisclaimerRoutes") || "{}"
      );

      // Only show if not already accepted for this route
      if (!acceptedRoutes[currentRoute]) {
        this.showDisclaimer = true;
      }
    },

    trackScroll(event) {
      const element = event.target;
      // Check if user has scrolled to the bottom (with a small tolerance)
      const scrolledToBottom = 
        Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 10;
      
      if (scrolledToBottom) {
        this.hasScrolledToBottom = true;
      }
    },

    acceptDisclaimer() {
      if (!this.hasScrolledToBottom) return;
      
      const currentRoute = this.$route.path;
      const acceptedRoutes = JSON.parse(
        localStorage.getItem("acceptedDisclaimerRoutes") || "{}"
      );

      acceptedRoutes[currentRoute] = true;
      localStorage.setItem(
        "acceptedDisclaimerRoutes",
        JSON.stringify(acceptedRoutes)
      );

      this.showDisclaimer = false;
      this.$emit("disclaimer-accepted");
    },

    declineDisclaimer() {
      this.$emit("disclaimer-declined");
    },
  },
};
</script>

<style scoped>
.disclaimer-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.disclaimer-content {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.disclaimer-header {
  padding: 16px 20px;
  background: #f8f9fa;
  border-bottom: 1px solid #e9ecef;
}

.disclaimer-title {
  font-weight: 600;
  font-size: 18px;
  color: #343a40;
}

.disclaimer-body {
  padding: 20px;
  max-height: 60vh;
  overflow-y: auto;
  color: #495057;
  line-height: 1.5;
}

.disclaimer-actions {
  display: flex;
  justify-content: flex-end;
  padding: 16px 20px;
  border-top: 1px solid #e9ecef;
  gap: 12px;
}

.btn-agree,
.btn-decline {
  padding: 8px 16px;
  border-radius: 20px; /* More rounded buttons */
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: all 0.2s;
  min-width: 100px;
}

.btn-agree {
  background-color: #dc3545;
  color: white;
}

.btn-agree:hover {
  background-color: #c82333;
}

.btn-agree:disabled {
  background-color: #e9acb2;
  cursor: not-allowed;
}

.btn-decline {
  background-color: #f8f9fa;
  color: #212529;
  border: 1px solid #ced4da;
}

.btn-decline:hover {
  background-color: #e9ecef;
}
</style>
<template>
  <div class="token-wrapper">
    <disclaimer-modal
      v-if="isDisclaimerRequired($route.path)"
      @disclaimer-accepted="onDisclaimerAccepted"
      @disclaimer-declined="onDisclaimerDeclined"
    />
    <div class="token" v-if="showContent">
      <div
        class="select-provider-wrapper d-flex justify-content-between mt-4 mt-md-0"
      >
        <div v-if="providers.length > 1" class="select-provider">
          <b-form>
            <b-form-group
              label="Data provider:"
              label-for="select-provider"
              label-cols="6"
              content-cols="6"
            >
              <b-form-select
                v-model="selectedProvider"
                :options="providers"
                id="select-provider"
              ></b-form-select>
            </b-form-group>
          </b-form>
        </div>
      </div>

      <div class="token-data-wrapper">
        <TokenPriceChartContainer
          :symbol="symbol"
          :key="symbol + selectedProvider + '-chart'"
          :provider="selectedProvider"
          :currentPrice="currentPrice"
        />

        <TokenPriceTableContainer
          id="token-price-table"
          :symbol="symbol"
          :key="symbol + selectedProvider + '-table'"
          :provider="selectedProvider"
          :currentPrice="currentPrice"
        />
      </div>

      <div class="space"></div>
    </div>
  </div>
</template>

<script>
  import redstoneAdapter from "@/redstone-api-adapter";
  import TokenPriceChartContainer from "@/components/Token/TokenPriceChartContainer";
  import TokenPriceTableContainer from "@/components/Token/TokenPriceTableContainer";
  import { mapActions } from "vuex";
  import { queryApiWithAlternativeProviders } from "@/tokens";
  import _ from "lodash";
  import DisclaimerModal from "../../../components/DisclaimerModal/DisclaimerModal.vue";
  import {
    isDisclaimerAccepted,
    isDisclaimerRequired,
  } from "../../../core/disclaimerHelper";
  export default {
    name: "Token",

    components: {
      TokenPriceChartContainer,
      TokenPriceTableContainer,
      DisclaimerModal,
    },

    data() {
      return {
        currentPrice: {},
        oldPrice: {},
        selectedProvider: null,
        tokenDetails: null,
        availableProviders: [],
        showContent: false,
      };
    },

    async created() {
      await this.initializeComponent();
      this.startPriceUpdates();
      this.checkContentAccess();
    },

    beforeDestroy() {
      this.stopPriceUpdates();
    },
    methods: {
      isDisclaimerRequired,
      checkContentAccess() {
        const currentRoute = this.$route.path;
        if (!isDisclaimerRequired(currentRoute)) {
          this.showContent = true;
          return;
        }
        this.showContent = isDisclaimerAccepted(currentRoute);
      },
      onDisclaimerAccepted() {
        this.showContent = true;
      },
      onDisclaimerDeclined() {
        this.$router.push("/app/tokens");
      },
      ...mapActions({
        getDetailsForSymbol: "manifests/getDetailsForSymbol",
      }),
      async initializeComponent() {
        const symbol = this.parseSymbol();
        this.tokenDetails = await this.getDetailsForSymbol(symbol);
        this.selectedProvider =
          this.tokenDetails?.providers[0] || "redstone-primary-prod";

        await this.loadPrices();
      },

      startPriceUpdates() {
        this.priceUpdateInterval = setInterval(async () => {
          await this.loadPrices();
        }, 2000);
      },

      stopPriceUpdates() {
        if (this.priceUpdateInterval) {
          clearInterval(this.priceUpdateInterval);
        }
      },

      async loadPrices() {
        try {
          if (this.symbol && this.selectedProvider) {
            this.currentPrice = await queryApiWithAlternativeProviders(
              this.selectedProvider,
              (provider) => {
                return redstoneAdapter.getPrice(this.symbol, {
                  provider: provider,
                });
              }
            );
          }
        } catch (error) {
          console.error("Error loading prices:", error);
        }
      },

      parseSymbol() {
        let symbol = this.$route.params.symbol;
        if (symbol.includes("-")) {
          symbol = symbol.replace("-", "/");
        }
        return symbol;
      },

      scrollToDataServices() {
        const table = document.getElementById("token-price-table");
        if (table) {
          table.scrollIntoView({ behavior: "smooth" });
        }
      },
    },

    computed: {
      symbol() {
        return this.parseSymbol();
      },

      providers() {
        if (!this.tokenDetails?.providers) {
          return [];
        }
        return this.tokenDetails.providers.map((provider) => ({
          value: provider,
          text: _.startCase(provider),
        }));
      },
    },

    watch: {
      selectedProvider: {
        handler: async function (newProvider) {
          if (newProvider) {
            await this.loadPrices();
          }
        },
      },

      symbol: {
        handler: async function () {
          await this.initializeComponent();
        },
      },
    },
  };
</script>
<style src="./Token.scss" lang="scss" scoped />
<style lang="scss" scoped>
  @import "~@/styles/app";

  .select-provider-wrapper {
    height: 24px;
    transform: translateY(-22px);
  }

  .select-provider {
    padding-left: 20px;

    .form-group {
      width: 500px;
      margin-bottom: 12px;
    }

    label {
      padding-top: 4px;
      font-weight: $font-weight-ultra-thin;
      font-size: $font-size-base;
      color: $gray-750;
      max-width: fit-content;
    }

    label + div {
      max-width: 200px;
    }

    .custom-select {
      border: solid 1px $gray-450;
      background-color: transparent;
      box-shadow: none;
      font-weight: $font-weight-soft-bold;
      background: transparent url("../../../assets/icons/select-down.svg") right
        1rem center/16px 16px no-repeat;
      border-radius: 7px;
      height: 28px;
      padding: 0 0 0 11px;
    }
  }

  .data-services-wrapper {
    line-height: 32px;
  }

  .data-services {
    font-size: $font-size-sm;
    margin-right: 18px;
  }

  .token-wrapper {
    scroll-behavior: smooth;
  }
</style>

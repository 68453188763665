<template>
  <div class="chart-card">
    <h3>TVS Over Time by Client</h3>
    <apexchart
      type="area"
      height="350"
      :options="chartOptions"
      :series="groupedClientSeries"
    />
  </div>
</template>

<script>
  import VueApexCharts from "vue-apexcharts";
  import _ from "lodash";

  export default {
    name: "TvsStackedChart",

    components: {
      apexchart: VueApexCharts,
    },

    props: {
      historicalData: {
        type: Object,
        required: true,
      },
      currentTvs: {
        type: Number,
        default: 0,
      },
      dropThreshold: {
        type: Number,
        default: 0.2, // 20% drop threshold
      },
    },

    computed: {
      groupedClientSeries() {
        if (!this.historicalData) return [];

        // Get all timestamps and clients
        const timestamps = Object.keys(this.historicalData).map(
          (ts) => parseInt(ts) * 1000
        );
        const allClients = new Set();
        Object.values(this.historicalData).forEach((dataPoint) => {
          Object.keys(dataPoint).forEach((client) => allClients.add(client));
        });

        // Calculate client data with averages
        const clientsData = Array.from(allClients).map((clientName) => {
          const values = timestamps.map((ts) => {
            const timestampData = this.historicalData[ts / 1000];
            return (timestampData?.[clientName] || 0);
          });

          return {
            name: clientName,
            values,
            avgTVS: _.mean(values),
          };
        });

        // Sort and split clients
        const sortedClients = _.orderBy(clientsData, ["avgTVS"], ["desc"]);
        const topClients = sortedClients.slice(0, 15);
        const otherClients = sortedClients.slice(15);

        // Calculate totals to detect drops
        const totals = timestamps.map((ts, idx) => ({
          ts,
          total: clientsData.reduce(
            (sum, client) => sum + (client.values[idx] || 0),
            0
          ),
        }));

        // Find last good data point before significant drop
        let lastValidIndex = totals.length - 1;
        for (let i = 1; i < totals.length; i++) {
          const drop =
            (totals[i - 1].total - totals[i].total) / totals[i - 1].total;
          if (drop > this.dropThreshold) {
            lastValidIndex = i - 1;
            break;
          }
        }

        // Add offset from right edge
        const futureTime = Date.now() + 12 * 60 * 60 * 1000;

        // Calculate the total TVS to determine proportions
        const totalTVS = _.sumBy(clientsData, "avgTVS");

        // Process top clients
        let series = topClients.map((client) => {
          const proportion = client.avgTVS / totalTVS;

          const data = timestamps
            .slice(0, lastValidIndex + 1)
            .map((ts, idx) => ({
              x: ts,
              y: Math.round(client.values[idx] || 0),
            }));

          // Add current value point with offset
          if (this.currentTvs) {
            data.push({
              x: futureTime,
              y: Math.round(this.currentTvs * proportion),
            });
          }

          return {
            name: client.name,
            data,
          };
        });

        // Process others
        if (otherClients.length > 0) {
          const othersProportion = _.sumBy(otherClients, "avgTVS") / totalTVS;

          const othersData = timestamps
            .slice(0, lastValidIndex + 1)
            .map((ts, idx) => ({
              x: ts,
              y: Math.round(
                otherClients.reduce(
                  (sum, client) => sum + (client.values[idx] || 0),
                  0
                )
              ),
            }));

          // Add current value point for others
          if (this.currentTvs) {
            othersData.push({
              x: futureTime,
              y: Math.round((this.currentTvs) * othersProportion),
            });
          }

          series.push({
            name: "Others",
            data: othersData,
          });
        }

        return series;
      },

      chartOptions() {
        return {
          chart: {
            type: "area",
            stacked: true,
            height: 350,
            background: "#13111b",
            foreColor: "#fff",
            toolbar: {
              show: false, // Hide toolbar
            },
            zoom: {
              enabled: false, // Disable zoom
            },
            selection: {
              enabled: false, // Disable selection
            },
            animations: {
              enabled: true,
            },
          },
          colors: [
            "#FF1C41",
            "#3ECF8E",
            "#3B82F6",
            "#F59E0B",
            "#8B5CF6",
            "#EC4899",
            "#10B981",
            "#6366F1",
            "#F97316",
            "#6B7280",
          ],
          stroke: {
            curve: "smooth",
            width: 2,
          },
          fill: {
            type: "gradient",
            gradient: {
              opacityFrom: 0.9,
              opacityTo: 0.5,
              stops: [0, 100],
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 0,
              sizeOffset: 0,
            },
          },
          xaxis: {
            type: "datetime",
            labels: {
              style: {
                colors: "#fff",
              },
              datetimeUTC: false,
              format: "MMM dd",
            },
            axisBorder: {
              color: "rgba(255,255,255,0.1)",
            },
            axisTicks: {
              color: "rgba(255,255,255,0.1)",
            },
            tooltip: {
              enabled: false,
            },
          },
          yaxis: {
            labels: {
              formatter: this.formatValue,
              style: {
                colors: "#fff",
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          tooltip: {
            enabled: true,
            shared: true,
            followCursor: true,
            intersect: false,
            theme: "dark",
            x: {
              show: true,
              format: "MMM dd, yyyy",
            },
            y: {
              formatter: this.formatValue,
            },
            marker: {
              show: true,
            },
            custom: this.customTooltip,
            onDatasetHover: {
              highlightDataSeries: false,
            },
          },
          legend: {
            show: false,
          },
          grid: {
            borderColor: "rgba(255,255,255,0.1)",
            padding: {
              top: 0,
              right: 0,
              bottom: 0,
              left: 10,
            },
            xaxis: {
              lines: {
                show: true,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
          },
        };
      },
    },

    methods: {
      formatValue(value) {
        if (value >= 1e9) return `$${(value / 1e9).toFixed(1)}B`;
        if (value >= 1e6) return `$${(value / 1e6).toFixed(1)}M`;
        if (value >= 1e3) return `$${(value / 1e3).toFixed(1)}K`;
        return `$${value}`;
      },

      formatTooltipValue(value) {
        if (value >= 1e9) return `$${(value / 1e9).toFixed(2)}B`;
        if (value >= 1e6) return `$${(value / 1e6).toFixed(2)}M`;
        if (value >= 1e3) return `$${(value / 1e3).toFixed(2)}K`;
        return `$${value.toFixed(2)}`;
      },

      customTooltip({ series, seriesIndex, dataPointIndex, w }) {
        const timestamp = w.globals.seriesX[0][dataPointIndex];
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString("en-US", {
          month: "short",
          day: "numeric",
          year: "numeric",
        });

        const values = series.map((s) => s[dataPointIndex] || 0);
        const total = values.reduce((sum, val) => sum + val, 0);

        let tooltipContent = `<div class="custom-tooltip">
        <div class="tooltip-date">${formattedDate}</div>`;

        series.forEach((s, index) => {
          const value = s[dataPointIndex] || 0;
          if (value > 0) {
            tooltipContent += `
            <div class="tooltip-row">
              <span class="bullet-wrapper">
                <span class="color-bullet" style="background-color: ${w.globals.colors[index]}"></span>
              </span>
              <span class="client-name">${w.globals.seriesNames[index]}</span>
              <span class="client-value">${this.formatTooltipValue(value)}</span>
            </div>`;
          }
        });

        tooltipContent += `
        <div class="tooltip-total">
          <span>Total:</span>
          <span>${this.formatTooltipValue(total)}</span>
        </div>
      </div>`;

        return tooltipContent;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .chart-card {
    background: #13111b;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
    width: 100%;

    h3 {
      color: #fff;
      margin-bottom: 20px;
    }
  }

  :deep(.custom-tooltip) {
    background: #1a1a1a;
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 8px 12px;
    border-radius: 4px;
    min-width: 200px;

    .tooltip-date {
      color: #fff;
      font-size: 14px;
      margin-bottom: 8px;
      padding-bottom: 8px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .tooltip-row {
      display: flex;
      align-items: center;
      margin: 4px 0;
      font-size: 12px;
      color: rgba(255, 255, 255, 0.7);

      .bullet-wrapper {
        display: flex;
        align-items: center;
        margin-right: 8px;
      }

      .color-bullet {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: inline-block;
      }

      .client-name {
        flex: 1;
        margin-right: 12px;
      }

      .client-value {
        text-align: right;
        font-family: monospace;
      }
    }

    .tooltip-total {
      display: flex;
      justify-content: space-between;
      margin-top: 8px;
      padding-top: 8px;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      font-weight: bold;
      color: #fff;

      span:last-child {
        font-family: monospace;
      }
    }
  }
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dashboard"
  }, [_c('img', {
    staticClass: "logo-image",
    attrs: {
      "src": "/redstone-logo-white.svg"
    }
  }), _vm.loading ? _c('div', {
    staticClass: "loading"
  }, [_vm._v("Loading dashboard data...")]) : _vm._e(), _vm.error ? _c('div', {
    staticClass: "error-message"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e(), !_vm.loading && !_vm.error ? _c('div', {
    staticClass: "dashboard__content"
  }, [_c('KeyMetricsGrid', {
    attrs: {
      "tvs-sum": _vm.tvsSum,
      "tvs-daily-change": _vm.tvsDailyChange,
      "active-providers": _vm.activeProviders,
      "daily-transactions": _vm.dailyTransactions,
      "daily-volume": _vm.dailyVolume,
      "tvs-per-client": _vm.tvsPerClient,
      "avg-response-time": _vm.avgResponseTime,
      "clients-tvl": _vm.clientsTvl
    }
  }), _c('TvsTimelineChart', {
    attrs: {
      "historical-tvs-per-client": _vm.filteredHistoricalTvsPerClient,
      "chart-theme": _vm.chartTheme
    }
  }), _c('TvsTimelineChartClient', {
    attrs: {
      "historical-data": _vm.filteredHistoricalTvsPerClient,
      "chart-theme": _vm.chartTheme,
      "color-palette": _vm.colorPalette
    }
  }), _c('ClientTreemap', {
    attrs: {
      "tvs-per-client": _vm.tvsPerClient,
      "chart-theme": _vm.chartTheme,
      "color-palette": _vm.colorPalette
    }
  }), _c('ClientList', {
    attrs: {
      "tvs-per-client": _vm.tvsPerClient,
      "color-palette": _vm.colorPalette
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "timeframe-selector"
  }, _vm._l(_vm.timeframes, function (tf) {
    return _c('button', {
      key: tf,
      class: ['timeframe-btn', {
        active: _vm.selectedTimeframe === tf
      }],
      on: {
        "click": function click($event) {
          return _vm.$emit('update:timeframe', tf);
        }
      }
    }, [_vm._v(" " + _vm._s(tf) + " ")]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }
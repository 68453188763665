var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "clients-section"
  }, [_c('div', {
    staticClass: "clients-section__header"
  }, [_c('h2', {
    staticClass: "clients-section__title"
  }, [_vm._v("Tvs Clients")]), _c('div', {
    staticClass: "clients-section__controls"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.sortOrder,
      expression: "sortOrder"
    }],
    staticClass: "clients-section__sort",
    on: {
      "change": function change($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.sortOrder = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "desc"
    }
  }, [_vm._v("Highest TVL First")]), _c('option', {
    attrs: {
      "value": "asc"
    }
  }, [_vm._v("Lowest TVL First")])])])]), !_vm.isListView ? _c('div', {
    staticClass: "clients-grid"
  }, _vm._l(_vm.sortedClients, function (client) {
    return _c('div', {
      key: client.name,
      staticClass: "client-card"
    }, [_c('div', {
      staticClass: "client-card__logo",
      style: {
        backgroundColor: _vm.getClientColor(client.name)
      }
    }, [_vm._v(" " + _vm._s(client.name.charAt(0)) + " ")]), _c('div', {
      staticClass: "client-card__info"
    }, [_c('h3', {
      staticClass: "client-card__name"
    }, [_vm._v(_vm._s(client.name))]), _c('p', {
      staticClass: "client-card__value"
    }, [_vm._v("$" + _vm._s(_vm.formatNumber(client.value)))]), _c('p', {
      staticClass: "client-card__percentage"
    }, [_vm._v(_vm._s(_vm.calculatePercentage(client.value)) + "% of total")])])]);
  }), 0) : _c('div', {
    staticClass: "clients-list"
  }, [_vm._m(0), _vm._l(_vm.sortedClients, function (client) {
    return _c('div', {
      key: client.name,
      staticClass: "clients-list__row"
    }, [_c('div', {
      staticClass: "clients-list__col"
    }, [_c('span', {
      staticClass: "clients-list__color-dot",
      style: {
        backgroundColor: _vm.getClientColor(client.name)
      }
    }), _vm._v(" " + _vm._s(client.name) + " ")]), _c('div', {
      staticClass: "clients-list__col"
    }, [_vm._v("$" + _vm._s(_vm.formatNumber(client.value)))]), _c('div', {
      staticClass: "clients-list__col"
    }, [_vm._v(_vm._s(_vm.calculatePercentage(client.value)) + "%")])]);
  })], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "clients-list__header"
  }, [_c('div', {
    staticClass: "clients-list__col"
  }, [_vm._v("Client")]), _c('div', {
    staticClass: "clients-list__col"
  }, [_vm._v("TVL Value")]), _c('div', {
    staticClass: "clients-list__col"
  }, [_vm._v("Percentage")])]);

}]

export { render, staticRenderFns }
<template>
  <div>
    <disclaimer-modal
      @disclaimer-accepted="onDisclaimerAccepted"
      @disclaimer-declined="onDisclaimerDeclined"
      v-if="isDisclaimerRequired($route.path)"
    />
    <div class="feed-details" v-if="showContent">
      <dl class="stats-grid">
        <div class="stat-item">
          <dt class="stat-title">Answer</dt>
          <dd
            class="stat-value"
            v-if="feedData?.apiValues?.value || feedData?.answer"
          >
            <strong
              v-if="!sUSDe_RATE"
              v-html="
                parseToCurrency(
                  feedData?.apiValues?.value || feedData?.answer,
                  denominationMap[feedData?.token]?.denomination,
                  feedData?.token,
                  feedData?.apiValues?.value == null && feedData?.answer != null
                )
              "
            ></strong>
            <strong v-else
              >{{
                currencySymbolMap[
                  denominationMap[feedData?.token]?.denomination
                ] || denominationMap[feedData.token]?.denomination
              }}
              <span
                v-html="
                  formatPriceWithoutCurrency(
                    feedData?.apiValues?.value || feedData?.answer,
                    sUSDe_RATE,
                    feedData?.apiValues?.value == null &&
                      feedData?.answer != null
                  )
                "
              ></span>
            </strong>
            <span
              class="timestamp"
              v-b-tooltip.hover
              :title="feedData.updateTime"
              >Updated {{ feedData.humanUpdateTime }}</span
            >
          </dd>
          <Loader v-else />
        </div>
        <div class="stat-item">
          <dt class="stat-title">Network</dt>
          <dd class="stat-value-wrapper">
            <div class="stat-value">
              <img
                class="feeds__token-image small"
                v-if="feedData"
                :src="feedData?.network.image"
                :alt="feedData?.network.name"
              />
              <span class="applicant-info__text">
                <a :href="feedData?.explorer?.explorerUrl" target="_blank">
                  {{ feedData?.network?.name }}
                </a>
              </span>
            </div>
          </dd>
        </div>
        <div class="stat-item">
          <dt class="stat-title">Symbol</dt>
          <dd class="stat-value">{{ feedData?.token }}</dd>
        </div>
        <div class="stat-item">
          <dt class="stat-title">Deviation threshold</dt>
          <dd class="stat-value">{{ feedData?.deviation }}</dd>
        </div>
        <div class="stat-item" v-if="feedData">
          <dt class="stat-title">Heartbeat</dt>
          <dd class="stat-value">
            <Loader
              v-if="
                feedData?.loaders?.blockTimestamp &&
                feedData?.apiValues?.timestamp == null
              "
              class="feeds__loader"
            />
            <span v-else class="feeds__timestamp">
              <span v-if="heartbeatIsNumber(feedData.heartbeat)">
                <div
                  style="
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  "
                >
                  <span> {{ feedData.heartbeatTitle }}</span>
                  <to-date-counter
                    class="ml-2"
                    :interval="feedData.heartbeatInterval"
                    :duration="feedData.heartbeat"
                  />
                </div>
              </span>
              <div v-else>
                <span
                  class="cron-trigger"
                  :id="`cron-trigger-${feedData.layer_id}`"
                >
                  <span>Cron: {{ feedData.heartbeatTitle }}</span>
                  <cron-counter :crons="feedData.heartbeat" class="ml-2" />
                </span>
              </div>
            </span>
          </dd>
        </div>
      </dl>
      <contract-address
        class="contract-address"
        v-if="feedData"
        :item="feedData"
        disable-truncate
        :separate-labels="false"
      />
      <div class="chart-container">
        <div class="feed-chart">
          <FeedChart
            v-if="currentChartData && !isLoading"
            :explorer="feedData?.explorer"
            :data="currentChartData"
            :range="currentRange"
            :denomination="denominationMap[feedData?.token]?.denomination"
            @range-change="handleRangeChange"
            @displayed-data-change="onDisplayedDataChange"
            :special-denomination="sUSDe_RATE"
            :duplicated-ranges="duplicateRanges.flat()"
            :currency="getCurrency(feedData.token)"
            :maxDataPoints="500"
            ref="feedChart"
          />
          <div class="loading-container" v-else>
            <vue-loaders-ball-beat
              color="var(--redstone-red-color)"
              scale="1"
            ></vue-loaders-ball-beat>
          </div>
        </div>
      </div>
    </div>
    <div class="feed-details sources mt-4">
      <div class="sources-title">
        Sources
        <RouterLink
          :to="{
            name: 'TokenPage',
            params: {
              symbol: feedData?.token.includes('/')
                ? feedData?.token.replace('/', '-')
                : feedData?.token,
            },
          }"
        >
          <span class="see-more-button">See more</span>
        </RouterLink>
      </div>
      <div class="sources-images" v-show="pullPrices != null" v-if="displayedSources">
        <div
          v-for="source in displayedSources"
          :key="source?.key"
          class="single-source"
          v-if="pullPrices?.[source.name] != null"
        >
          <img
            :src="source?.imageUrl"
            :title="source?.name"
            :alt="source?.name"
            v-b-tooltip.hover
          />
          <div class="source-price">
            <span
              v-if="!sUSDe_RATE"
              v-html="
                parseToCurrency(
                  pullPrices[source?.name],
                  denominationMap[feedData?.token]?.denomination,
                  feedData?.token,
                  feedData?.apiValues?.value == null && feedData?.answer != null
                )
              "
            ></span>
            <span v-else
              >{{
                currencySymbolMap[
                  denominationMap[feedData?.token]?.denomination
                ] || denominationMap[feedData.token]?.denomination
              }}
              <span
                v-html="
                  formatPriceWithoutCurrency(
                    pullPrices[source.name],
                    sUSDe_RATE,
                    feedData?.apiValues?.value == null &&
                      feedData?.answer != null
                  )
                "
              ></span>
            </span>
          </div>
        </div>
      </div>
      <vue-loaders-ball-beat
        color="var(--redstone-red-color)"
        scale="0.4"
        v-if="pullPrices == null"
      ></vue-loaders-ball-beat>
    </div>
    <div class="feed-details mt-4">
      <TransactionsTable
        :explorer="feedData?.explorer"
        :data="displayedData.length > 0 ? displayedData : currentChartData"
        :special-denomination="sUSDe_RATE"
        :denomination="
          currencySymbolMap[denominationMap[feedData?.token]?.denomination] ||
          denominationMap[feedData?.token]?.denomination
        "
        :token="feedData?.token"
        :big-dec="
          feedData?.apiValues?.value == null && feedData?.answer != null
        "
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from "vuex";
  import redstoneAdapter from "@/redstone-api-adapter";
  import FeedChart from "./components/FeedChart/FeedChart.vue";
  import ContractAddress from "./components/ContractAddress.vue";
  import ToDateCounter from "./components/ToDateCounter.vue";
  import CronCounter from "./components/CronCounter.vue";
  import sourcesData from "@/config/sources.json";
  import {
    mapFeedsData,
    parseToCurrency,
    toUrlParam,
    findNetworkName,
    heartbeatIsNumber,
    currencySymbolMap,
    formatPriceWithoutCurrency,
  } from "./utils/FeedsTableDataLayer";
  import TimestampWithLoader from "./components/TimestampWithLoader.vue";
  import Loader from "./../../../components/Loader/Loader.vue";
  import HeartbeatTimer from "./components/HeartbeatTimer.vue";
  import axios from "axios";
  import DisclaimerModal from "../../../components/DisclaimerModal/DisclaimerModal.vue";
  import {
    isDisclaimerAccepted,
    isDisclaimerRequired,
  } from "../../../core/disclaimerHelper";
  import TransactionsTable from "./components/TransactionsTable.vue";
  import { pull } from "lodash";

  export default {
    components: {
      FeedChart,
      ContractAddress,
      TimestampWithLoader,
      HeartbeatTimer,
      Loader,
      ToDateCounter,
      CronCounter,
      DisclaimerModal,
      TransactionsTable,
    },
    data() {
      return {
        showContent: false,
        isLoading: false,
        chartDataCache: {
          "1d": null,
          "1w": null,
          "1m": null,
        },
        currentRange: "1w",
        displayedData: [],
        duplicateRanges: [],
        rawChartData: null,
        currencySymbolMap,
        pullPrices: null,
      };
    },
    created() {
      this.checkContentAccess();
    },
    async mounted() {
      await this.fetchRelayerSchema();
      await this.fetchAllValues();
      setInterval(this.fetchAllValues, 15 * 1000);
    },
    methods: {
      async fetchAllValues() {
        await this.fetchPullPrices();
        await this.fetchRelayerValues();
        await this.initializeData();
      },
      formatPriceWithoutCurrency,
      parseToCurrency,
      heartbeatIsNumber,
      isDisclaimerRequired,
      async fetchPullPrices() {
        const { source } = await redstoneAdapter.getPrice(this.feedData.token, {
          provider: "redstone-primary-prod",
        });
        this.pullPrices = source;
      },
      checkContentAccess() {
        const currentRoute = this.$route.path;
        if (!isDisclaimerRequired(currentRoute)) {
          this.showContent = true;
          return;
        }
        this.showContent = isDisclaimerAccepted(currentRoute);
      },
      onDisplayedDataChange(data) {
        this.displayedData = data.map(({ x, y, txHash }) => ({
          value: y,
          timestamp: x,
          txHash,
        }));
      },
      onDisclaimerAccepted() {
        this.showContent = true;
      },
      onDisclaimerDeclined() {
        this.$router.push("/app/feeds/");
      },
      hexToPrice(hex) {
        let decimalValue = parseInt(hex, 16);
        let price = decimalValue / 100000000;
        return price;
      },
      ...mapActions("feeds", [
        "initSingleContract",
        "fetchRelayerSchema",
        "fetchRelayerValues",
      ]),
      async fetchChartData() {
        if (this.rawChartData) {
          return this.rawChartData;
        }
        try {
          const endpoint = this.getChartEndpoint(
            this.feedData.heartbeatInterval > 60000 ? 30 : 25
          );
          const { data } = await axios.get(endpoint);
          this.rawChartData = data.onChainUpdates;
          return this.rawChartData;
        } catch (error) {
          console.error(`Error fetching chart data:`, error);
          return null;
        }
      },
      getCurrency(token) {
        const currency = token.split("/")[1];
        let symbol = "$";
        if (currency && currency !== "USD") {
          switch (currency) {
            case "EUR":
              symbol = "€";
              break;
            case "ETH":
              symbol = "Ξ";
              break;
            default:
              symbol = currency;
          }
        }
        return symbol;
      },
      async fetchValues() {
        const rawData = await this.fetchChartData();
        if (rawData) {
          this.mapDataToRanges(rawData);
          this.compareDateSets();
        }
      },
      async initializeData() {
        this.isLoading = true;
        try {
          await this.fetchValues();
        } catch (error) {
          console.error("Error initializing data:", error);
        } finally {
          this.isLoading = false;
        }
      },
      mapDataToRanges(rawData) {
        const now = Date.now();
        const oneDayAgo = now - 24 * 60 * 60 * 1000;
        const oneWeekAgo = now - 7 * 24 * 60 * 60 * 1000;

        this.chartDataCache["1m"] = rawData;
        this.chartDataCache["1w"] = rawData.filter(
          (item) => item.timestamp >= oneWeekAgo
        );
        this.chartDataCache["1d"] = rawData.filter(
          (item) => item.timestamp >= oneDayAgo
        );
      },
      compareDateSets() {
        const ranges = ["1d", "1w", "1m"];
        this.duplicateRanges = [];
        let equalPairs = [];
        let singleItemRanges = [];
        for (let i = 0; i < ranges.length; i++) {
          if (this.chartDataCache[ranges[i]]?.length === 1) {
            singleItemRanges.push(ranges[i]);
          }
          for (let j = i + 1; j < ranges.length; j++) {
            if (
              this.areDataSetsEqual(
                this.chartDataCache[ranges[i]],
                this.chartDataCache[ranges[j]]
              )
            ) {
              equalPairs.push([ranges[i], ranges[j]]);
            }
          }
        }
        if (equalPairs.length === 3) {
          this.duplicateRanges = [ranges[1], ranges[2]];
          this.chartDataCache[ranges[1]] = this.chartDataCache[ranges[2]] =
            this.chartDataCache[ranges[0]];
        } else if (equalPairs.length === 1) {
          const [smaller, larger] = equalPairs[0];
          this.duplicateRanges = [larger];
          this.chartDataCache[larger] = this.chartDataCache[smaller];
        }
        this.duplicateRanges.push(...singleItemRanges);
        this.duplicateRanges = [...new Set(this.duplicateRanges)];
      },
      areDataSetsEqual(dataset1, dataset2) {
        if (!dataset1 || !dataset2 || dataset1.length !== dataset2.length) {
          return false;
        }

        return dataset1.every(
          (item, index) =>
            item.timestamp === dataset2[index].timestamp &&
            item.value === dataset2[index].value
        );
      },
      handleRangeChange(range) {
        this.currentRange = range;
      },
      getChartEndpoint(daysRange) {
        const baseUrl = "https://api.redstone.finance/on-chain-updates";
        const dataFeedId = this.feedData?.token || "ETH";
        const adapterName = this.feedData?.relayerId;
        return `${baseUrl}?dataFeedId=${dataFeedId}&adapterName=${adapterName}&daysRange=${daysRange}&interval=10m`;
      },
      parseJSON(data) {
        try {
          return Object.entries(data).map(([key, value]) => ({
            ...value,
            id: key,
          }));
        } catch (error) {
          console.error("Error parsing JSON:", error);
          return [];
        }
      },
    },
    watch: {
      relayerId() {
        if (
          this.relayerId &&
          this.getSmartContractByLayerId(this.relayerId) == null
        ) {
          this.initSingleContract({
            layerId: this.relayerId,
            feedId: this.feedData?.token,
          });
        }
      },
    },
    computed: {
      sources() {
        return this.getSymbolDetails?.[this.feedData?.token]?.config?.source;
      },
      displayedSources() {
        if (this.sources == null) return [];
        return this.sources?.map((source) => ({
          key: source,
          name: source,
          imageUrl: this.parseJSON(sourcesData).find(
            (dataSource) => dataSource.id.split("-")[0] === source?.split("-")[0]
          )?.logoURI,
        }));
      },
      sUSDe_RATE() {
        return this.feedData?.token === "sUSDe_RATE_PROVIDER";
      },
      feedsInRelayer() {
        return this.relayerSchema[this.relayerId].priceFeeds;
      },
      network() {
        return this.$route.params?.network;
      },
      token() {
        return this.$route.params.token;
      },
      getFeedDetails(relayerId) {
        return mapFeedsData(
          this.combinedFeedsWithDetailsArray.filter(
            (feed) => feed.layerId === relayerId
          )
        );
      },
      feedData() {
        return mapFeedsData(
          this.combinedFeedsWithDetailsArray.filter(
            (feed) =>
              toUrlParam(findNetworkName(feed?.networkId)) === this.network &&
              toUrlParam(feed.feedId) === this.token
          )
        )[0];
      },
      relayerId() {
        return this.feedData?.relayerId;
      },
      currentChartData() {
        return this.chartDataCache[this.currentRange];
      },
      ...mapState("feeds", ["relayersDetails", "relayerSchema"]),
      ...mapGetters("feeds", [
        "combinedFeedsWithDetailsArray",
        "getSmartContractByLayerId",
      ]),
      ...mapGetters("manifests", ["denominationMap", "getSymbolDetails"]),
      layer() {
        return this.relayerSchema[this.relayerId];
      },
    },
  };
</script>
<style lang="scss" scoped src="./Feed.scss" />
